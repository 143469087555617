import mapboxgl from "!mapbox-gl";
import CommonHelper from "@/helpers/common.helper";
import HotelHelper from "@/helpers/hotel.helper.js";

/**
 * Creates a Mapbox layer configuration object.
 *
 * @param {string} id - The unique identifier for the layer.
 * @param {string} source - The source ID for the layer.
 * @returns {Object} The layer configuration object.
 */
const getLayer = (id, source) => {
  return {
    id: id,
    type: "circle",
    source: source,
    paint: {
      "circle-color": "#4264fb",
      "circle-radius": 8,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff"
    },
    metadata: {
      isCustom: true
    }
  };
};

/**
 * Creates a Mapbox source configuration object from hotel markers.
 *
 * @param {Array} hotelMarkers - An array of hotel marker objects.
 * @returns {Object} The source configuration object.
 */
const getSource = (hotelMarkers) => {
  return {
    type: "geojson",
    data: {
      isCustom: true,
      type: "FeatureCollection",
      features: hotelMarkers
    }
  };
};

/**
 * Converts a hotel object into a GeoJSON feature object.
 *
 * @param {Object} hotel - The hotel object.
 * @param {number} hotel.hotelId - The unique identifier for the hotel.
 * @param {number} hotel.longitude - The longitude of the hotel.
 * @param {number} hotel.latitude - The latitude of the hotel.
 * @returns {Object} The GeoJSON feature object representing the hotel.
 */
const getMarkerFromHotel = (hotel) => {
  return {
    type: "Feature",
    id: `hotelMarker-${hotel.hotelId}`,
    properties: {
      hotelId: hotel.hotelId
    },
    geometry: {
      type: "Point",
      coordinates: [hotel.longitude, hotel.latitude]
    }
  };
};

/**
 * Generates an HTML string for a popup description of a selected hotel. The description includes the hotel name, star rating, thumbnail image, and a link to the hotel's page.
 * If the hotel has prices available, the lowest price is displayed
 * This is displayed when a marker is clicked
 *
 * @param {Object} selectedHotel - The selected hotel object.
 * @param {string} selectedHotel.name - The name of the hotel.
 * @param {number} selectedHotel.stars - The star rating of the hotel.
 * @param {string} [selectedHotel.thumbnail] - The URL of the hotel's thumbnail image.
 * @param {string} selectedHotel.link - The URL link to the hotel's page.
 * @param {Array} selectedHotel.suppliers - The list of suppliers for the hotel.
 * @param {Function} $t - Translation function for localizing text.
 * @returns {string} - The HTML string for the popup description.
 */
const getPopupDescription = (selectedHotel, $t) => {
  let description = `<div class="c-map-popup">
  <h6  class='title is-6 mb-1'>${selectedHotel.name}</h6>`;

  if (selectedHotel.stars > 0) {
    let stars = selectedHotel.stars;
    description += `<div class="results-list__item__stars">`;

    for (let i = 1; i <= Math.floor(stars); i++) {
      description += `<i class="fas fa-star"></i>`;
    }
    if (stars % 1 > 0) {
      description += `<i class="fas fa-star-half"></i>`;
    }
    description += `</div>`;
  }
  let lowestOffer = HotelHelper.getPriceSummary(
    selectedHotel?.suppliers
  ).lowestOffer;

  if (selectedHotel.thumbnail) {
    description += `<div class='c-map-popup--image'><img width="200" src="${selectedHotel.thumbnail}" /></div>`;
  }
  description += `<div class='c-map-popup--description'>`;
  if (lowestOffer) {
    description += `
      ${$t("priceFrom")}: ${CommonHelper.formatPrice(lowestOffer.price)} `;
  } else {
    description += `${$t("noPrices")}`;
  }
  description += `</div>`;
  description += `<a href="${selectedHotel.link}">${$t("viewHotel")}`;
  description += `</div>`;
  return description;
};

/**
 * Creates a GeoJSON Point feature from a given location object.
 *
 * @param {Object} location - The location object containing latitude and longitude.
 * @param {number} location.latitude - The latitude of the location.
 * @param {number} location.longitude - The longitude of the location.
 * @returns {Object} A GeoJSON Point feature representing the location.
 */
const getMarkerFromSearch = (location) => {
  let marker = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: [location.longitude, location.latitude]
    }
  };
  return marker;
};

/**
 * Initializes and returns a new Mapbox map instance.
 *
 * @param {string} mapContainer - The ID of the HTML element to contain the map.
 * @param {boolean} [showControls=true] - Optional flag to show navigation controls on the map.
 * @returns {mapboxgl.Map} - The initialized Mapbox map instance.
 */
const getNewMap = (mapContainer, showControls = true) => {
  let map = new mapboxgl.Map({
    container: mapContainer,
    // eslint-disable-next-line no-undef
    style: process.env.VUE_APP_MAPBOX_STYLE,
    zoom: 15
  });
  if (showControls) {
    map.addControl(new mapboxgl.NavigationControl());
  }
  return map;
};

/**
 * Clears custom layers and sources from the map.
 *
 * @param {Object} map - The map instance to clear layers and sources from.
 * @param {Array} layers - An array of layer objects to filter and remove.
 * @param {Object} sources - An object containing source data to filter and remove.
 */
const clearMap = (map, layers, sources) => {
  let customLayers = layers.filter((l) => l.metadata && l.metadata.isCustom);

  customLayers.forEach((l) => {
    map.removeLayer(l.id);
  });

  Object.keys(sources).forEach((sourceId) => {
    let source = sources[sourceId];

    if (source.data && source.data.isCustom) {
      map.removeSource(sourceId);
    }
  });
};

export default {
  getLayer,
  getMarkerFromHotel,
  getPopupDescription,
  getMarkerFromSearch,
  getSource,
  getNewMap,
  clearMap
};
