import { createI18n, VERSION } from "vue-i18n";

// the translation files are in /src/locales
import nl from "@/locales/nl.json";
import en from "@/locales/en.json";
//dubbel blabla, maar wel streng!
if (
  !localStorage.locale ||
  localStorage.locale == null ||
  localStorage.locale == ""
) {
  localStorage.locale = "nl";
}

// If none is set; its nl
const selectedLocale = localStorage.locale ?? "nl";

const i18n = createI18n({
  locale: selectedLocale,
  warnHtmlMessage: "off",
  fallbackLocale: "nl",
  messages: {
    nl,
    en
  }
});
export default i18n;
