<template>
  <div @click="hideBookingRules()">
    <h5
      class="title is-5 rules-header mb-0"
      style="user-select: none; cursor: pointer"
    >
      <i
        class="fa-solid"
        :class="{
          'fa-chevron-up': bookingRulesHidden,
          'fa-chevron-down': !bookingRulesHidden
        }"
      ></i>
      {{ $t("bookingRules") }}
    </h5>
    <ul v-if="!bookingRulesHidden">
      <li>
        {{ $t("resultsPage.priceDisclaimer") }}
      </li>
      <li v-if="budget">
        {{ $t("resultsPage.budget", { budget: budget }) }}
      </li>
      <template v-for="a in annotations" v-bind:key="a.title">
        <li class="pt-3">
          <b>{{ a.title }}</b
          ><br />
          <div v-html="a.text"></div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import CommonHelper from "@/helpers/common.helper";

export default {
  data() {
    return {
      annotations: [],
      budget: null,
      bookingRulesHidden: false
    };
  },
  async mounted() {
    this.bookingRulesHidden = localStorage.bookingRulesHidden;
    let companyInfo = await CommonHelper.getCompanyInfo();
    if (companyInfo && companyInfo.budget) {
      this.budget = companyInfo.budget;
    }
    let annotations = await CommonHelper.getCompanyAnnotations();
    this.annotations = annotations;
  },
  methods: {
    hideBookingRules() {
      this.bookingRulesHidden = !this.bookingRulesHidden;
      localStorage.bookingRulesHidden = this.bookingRulesHidden;
    }
  }
};
</script>

<style scoped></style>
