<template>
  <div class="details">
    <div @click="showMap" class="details__title">
      <div id="details-map" class="details__map"></div>
      <div class="wrapper" v-if="details && !isLoading">
        <template v-if="isHotelReservation()">
          <span
            class="is-flex is-justify-content-space-between is-align-content-baseline is-flex-direction-row"
          >
            <h1>
              {{
                $t("ReservationForm.requestReservation", { name: details.name })
              }}
              <HotelStars
                v-if="details.content?.starRating?.rating"
                :stars="details.content?.starRating?.rating"
              />
            </h1>

            <button
              v-if="locationPath !== 'direct'"
              @click="gotoResults()"
              class="button is-primary is-small"
            >
              {{ $t("backToSearch") }}
            </button>
          </span>
        </template>
        <template v-else-if="reservationType === 'city'">
          <h1>{{ $t("ReservationForm.requestCityReservation", { city }) }}</h1>
        </template>
      </div>
    </div>

    <div
      class="details__content container loading-container"
      v-if="isLoading && !isPricesLoading"
    >
      <h3 class="title is-3">{{ $t("ReservationForm.loading") }}</h3>
      <br />
      <img width="50" height="60" src="@/assets/images/loader.svg" />
    </div>
    <div v-else class="container">
      <template v-if="!isHotelReservation()">
        <div class="details__content__box">
          <h1 class="mb-3">
            {{ $t("ReservationForm.requestCityReservation", { city }) }}
          </h1>
          <p class="mb-5">
            {{ $t("ReservationForm.cityReservationIntroduction") }}
          </p>
        </div>
      </template>
      <div class="columns is-multiline details__content__box">
        <div
          class="field column is-one-third-desktop"
          v-if="
            companyInfo.userSettings?.canBookOnBehalfOfOthers &&
            companyInfo.allowListedEmployees &&
            businessUnits
          "
        >
          <h5 class="title is-5">{{ $t("ReservationForm.findEmployee") }}</h5>

          <EmployeeSelection
            :pre-selected-employees="guests"
            :business-units="businessUnits"
            :selected-employees="selectedEmployees"
            @selected-employee="addEmployee"
          />
          <p v-if="companyInfo.allowListedEmployees">
            {{ $t("ReservationForm.unknownEmployee") }}
          </p>
        </div>
        <template
          v-if="
            !companyInfo?.userSettings?.canBookOnBehalfOfOthers ||
            (!companyInfo.allowUnlistedEmployees &&
              !companyInfo.allowListedEmployees)
          "
        >
          <div class="field column is-one-third-desktop">
            <h5 class="title is-5">
              {{ $t("ReservationForm.personalReservationHeader") }}
            </h5>

            <p>{{ $t("ReservationForm.personalReservation") }}</p>
          </div>
        </template>

        <template
          v-if="
            companyInfo.userSettings?.canBookOnBehalfOfOthers &&
            companyInfo.allowUnlistedEmployees
          "
        >
          <div class="field column is-one-third-desktop">
            <AddUnlistedEmployee
              @created-unlisted-employee="addUnlistedEmployee"
            />
          </div>
        </template>

        <div class="field column is-one-third-desktop">
          <template v-if="companyInfo.employeeIsRequired">
            <h5 class="title is-5">
              {{ $t("ReservationForm.selectedGuests") }}
            </h5>

            <br />
            <i
              v-if="
                selectedEmployees.length == 0 &&
                selectedUnlistedEmployees.length == 0
              "
            >
              {{ $t("ReservationForm.noGuestsAdded") }}</i
            >
            <template v-for="e in selectedEmployees" :key="e">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <strong>{{ e.firstName }} {{ e.lastName }}</strong>
                <button class="button is-small is-danger">
                  <span
                    class="icon is-small is-danger"
                    @click.stop.prevent="removeSelectedEmployee(e)"
                  >
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </template>
            <br />
            <br />
          </template>
          <template v-for="e in selectedUnlistedEmployees" :key="e">
            <div
              class="is-flex is-align-items-center is-justify-content-space-between mb-2"
            >
              <strong
                >{{ e.firstName }} {{ e.lastName }} ({{
                  e.emailAddress
                }})</strong
              >
              <button class="button is-small is-danger">
                <span
                  class="icon is-small is-danger"
                  @click.stop.prevent="removeUnlistedEmployees(e)"
                >
                  <i class="fas fa-times"></i>
                </span>
              </button>
            </div>
            <br />
            <br />
          </template>
          <template v-if="businessUnits?.length > 1">
            <h5 class="title is-6">
              {{ tbu("selectedBusinessUnit") }}
            </h5>
            <br />
            <template v-if="selectedBusinessUnit">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <b> {{ selectedBusinessUnit.name }}</b>
                <button class="button is-small is-warning">
                  <span
                    class="icon is-small"
                    @click.stop.prevent="showBusinessUnitSearch = true"
                  >
                    <i class="fas fa-edit"></i>
                  </span>
                </button>
                <button
                  class="button is-small is-danger"
                  v-if="!companyInfo.businessUnitMandatory"
                >
                  <span
                    class="icon is-small is-danger"
                    @click.stop.prevent="removeBusinessUnit()"
                  >
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </template>
            <BusinessunitSelection
              v-if="showBusinessUnitSearch"
              :business-units="businessUnits"
              :preselected-business-unit-id="preselectedBusinessUnitId"
              @selected-business-unit="selectBusinessUnit"
            />
          </template>
        </div>
      </div>

      <complex-booking
        ref="complexBookingComp"
        @selected-dates="complexDatesSelected"
      ></complex-booking>

      <form @submit.prevent="makeReservation()">
        <div class="columns is-multiline details__content__box mb-5">
          <div class="field column is-half-desktop">
            <label for="notes">{{ $t("ReservationForm.notes") }} </label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="notes"
                id="notes"
                name="notes"
                type="text"
                rows="5"
                :placeholder="$t('ReservationForm.notesPlaceholder')"
              ></textarea>
            </div>
            <template
              v-for="field in companyInfo.customFields"
              :key="field.name"
            >
              <CustomField
                :customField="field"
                :customFieldFormData="customFieldFormData"
                @setted-value="setValue"
              ></CustomField>
            </template>
          </div>

          <div class="field column is-half-desktop" v-if="isHotelReservation()">
            <div class="columns is-multiline" style="display: flex">
              <div class="column is-full content pb-0 mb-0">
                <h5>{{ $t("stayPeriod") }}</h5>
                <p>
                  {{ $t("complexBooking.introText") }}
                  <br />
                  <br />
                  <a
                    class="has-text-weight-semibold"
                    @click="initComplexBooking()"
                  >
                    {{ $t("complexBooking.launchModal") }}
                    <i class="fa fa-edit"></i>
                  </a>
                </p>
                <hr />
              </div>
              <template v-if="!isComplexBooking">
                <div class="column is-three-fifths is-full-mobile">
                  <i class="fa fa-calendar"></i>
                  {{ startDateString }} - {{ endDateString }}

                  ({{
                    $t("nightsWithLabel", { count: reservationMeta.nights })
                  }})
                </div>

                <div class="column is-one-quarter-mobile">
                  <a @click="showEditableFields = !showEditableFields">
                    {{ $t("editReservationMeta") }}
                    <i class="fa fa-edit"></i>
                  </a>
                </div>
              </template>
              <template v-else>
                <div class="column is-full">
                  <p>{{ $t("complexBooking.chosenData") }}</p>

                  <ul class="my-3">
                    <li
                      v-for="selectedDate in complexBooking.selectedDates"
                      :key="selectedDate"
                    >
                      <span class="date-label--day">
                        {{ formatDateLabelDay(selectedDate) }}
                      </span>

                      {{ formatDateLabel(selectedDate) }}
                    </li>
                  </ul>
                  <p>
                    {{ $t("complexBooking.chosenFor") }}
                    {{ complexBooking.breakfast ? "" : "geen" }}
                    {{ $t("breakfast") }} en
                    {{ complexBooking.refundable ? "" : "niet" }}
                    {{ $t("refundable") }}
                  </p>
                </div>
              </template>
            </div>
            <div
              class="columns"
              style="max-width: 800px"
              v-if="showEditableFields"
            >
              <div class="column">
                <Datepicker
                  v-model="dateRange"
                  :minDate="new Date()"
                  @update:modelValue="handleDate"
                  :enableTimePicker="false"
                  :range="true"
                  inline
                  multiCalendars
                  autoApply
                  locale="nl"
                />
                <p class="pt-3">
                  {{ $t("selectCheckinAndCheckOutDate") }}
                </p>

                <button
                  class="button is-success is-small mt-4"
                  @click="fetchNewPrices()"
                >
                  <span class="icon is-small"
                    ><i class="fas fa-save" aria-hidden="true"></i></span
                  ><span>
                    {{ $t("saveAndLoadPrices") }}
                  </span>
                </button>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <label for="rooms" style="padding-top: 12px"
                  >{{ $t("searchFilters.numberOfRooms") }}
                </label>
              </div>
              <div class="column">
                <div class="input-icon" style="max-width: 150px">
                  <div class="input-icon--wrapper">
                    <i class="fas fa-tent" aria-hidden="true"></i>
                  </div>
                  <input
                    id="rooms"
                    type="number"
                    min="1"
                    :max="numberOfPersons || 1"
                    @change="fetchNewPrices()"
                    :placeholder="$t('searchFilters.numberOfRooms')"
                    v-model="reservationMeta.rooms"
                  />
                </div>
              </div>
            </div>

            <template v-if="isPricesLoading">
              <img width="50" height="60" src="@/assets/images/loader.svg" />
            </template>
            <template v-else-if="!isComplexBooking">
              <label>&nbsp; </label>
              <SelectOffer
                v-if="details"
                :suppliers="details?.suppliers"
                @selected-offer="setSelectedOffer"
                :breakfastIncluded="this.breakfastIncluded"
              />
              <p
                v-if="
                  selectedOfferData &&
                  budget &&
                  parseFloat(selectedOfferData.price / reservationMeta.nights) >
                    parseFloat(budget)
                "
                class="has-text-black mb-2 has-background-warning p-3"
              >
                {{ $t("warningOverBudget", { budget }) }}
              </p>
              <p
                v-if="selectedOfferData && selectedOfferData.isPlaceholder"
                class="has-text-white mb-2 has-background-info p-3"
              >
                {{ $t("offerNotOnline") }}
              </p>
            </template>
            <template v-if="locationPath === 'direct' && 1 == 2">
              <div class="notification is-warning is-size-7 has-text-centered">
                <p>
                  <i
                    style="color: orange"
                    class="fa-solid fa-triangle-exclamation"
                  ></i>
                  {{ $t("directBookingNotice") }}
                  <i
                    style="color: orange"
                    class="fa-solid fa-triangle-exclamation"
                  ></i>
                </p>
              </div>
            </template>
            <div class="control is-flex is-align-content-baseline">
              <button
                type="submit"
                class="c-button is-primary is-medium"
                :disabled="isSaving || disableButton"
              >
                {{ treq("requestReservation") }}
              </button>
              <template v-if="isSaving">
                &nbsp;&nbsp;&nbsp;
                <img width="50" height="50" src="@/assets/images/loader.svg" />
              </template>
            </div>
          </div>
          <div
            class="field column is-half-desktop"
            v-if="!isHotelReservation()"
          >
            <p class="mb-5">
              {{ $t("enteredAllWishes") }}
            </p>
            <div class="control is-flex is-align-content-baseline">
              <button
                type="submit"
                :disabled="isSaving || disableButton"
                class="c-button is-primary is-medium"
              >
                {{ treq("requestReservation") }}
              </button>
              <template v-if="isSaving">
                &nbsp;&nbsp;&nbsp;
                <img width="50" height="50" src="@/assets/images/loader.svg" />
              </template>
            </div>
          </div>

          <div class="field column is-full" v-if="saveData && 2 == 3">
            <h1>Deze data gaan we posten</h1>
            <pre
              >{{ saveData }}
          </pre
            >
            <h1>Deze data kwam terug</h1>
            <pre
              >{{ returnedData }}
          </pre
            >
          </div>
        </div>
      </form>
    </div>

    <NotificationMessage
      :level="notification.level"
      :autohide="false"
      :title="notification.title"
      :message="notification.message"
      :show="notification.show"
      @toggle="toggleNotification"
    />
  </div>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import CommonHelper from "@/helpers/common.helper.js";
import ReservationHelper from "@/helpers/reservation.helper.js";

import HotelStars from "./components/hoteldetail/HotelStars.vue";
import Datepicker from "@vuepic/vue-datepicker";

import SelectOffer from "./components/hoteldetail/SelectOffer.vue";
import BusinessunitSelection from "./components/reservationform/BusinessunitSelection.vue";
import EmployeeSelection from "./components/reservationform/EmployeeSelection.vue";
import AddUnlistedEmployee from "./components/reservationform/AddUnlistedEmployee.vue";
import CustomField from "./components/reservationform/CustomField.vue";

import ComplexBooking from "./components/reservationform/ComplexBooking.vue";

import moment from "moment";

export default {
  props: {
    reservationType: {
      type: String,
      default: "hotel"
    },
    hotelId: {
      type: String,
      default: ""
    },
    locationPath: {
      type: String,
      default: ""
    },
    city: {
      type: String,
      default: ""
    },
    checkin: {
      type: String,
      default: null
    },
    nights: {
      type: String,
      default: null
    },
    adults: {
      type: String,
      default: "1"
    },
    rooms: {
      type: String,
      default: "1"
    },
    preselectedBusinessUnitId: {
      type: String,
      default: null
    },
    guests: {
      type: String,
      default: ""
    },
    feedbackId: {
      type: String,
      default: null
    },
    refundable: {
      type: String,
      default: "true"
    },
    latitude: {
      type: String,
      default: null
    },
    longitude: {
      type: String,
      default: null
    },
    breakfastIncluded: {
      type: String,
      default: "false"
    }
  },
  components: {
    Datepicker,
    SelectOffer,
    HotelStars,
    BusinessunitSelection,
    EmployeeSelection,
    AddUnlistedEmployee,
    ComplexBooking,
    CustomField
  },
  data() {
    return {
      isLoading: false,
      isPricesLoading: false,
      showBusinessUnitSearch: true,
      disableButton: false,
      details: {},
      notes: "",
      fetchedFeedbackId: null,
      showEditableFields: false,
      budget: null,
      saveData: null,
      selectedOfferData: null,
      returnedData: null,
      startDate: "",
      endDate: "",
      businessUnits: null,
      allowBusinessUnitClearing: false,
      selectedEmployees: [],
      selectedUnlistedEmployees: [],
      selectedBusinessUnit: null,
      errorMessages: [],
      companyInfo: {},
      reservationMeta: {
        checkin: moment().add(1, "days").format("YYYY-MM-DD"),
        nights: 1,
        rooms: 1,
        refundable: true,
        adults: 1,
        breakfastIncluded: false
      },
      customFieldFormData: {},
      dateRange: {
        startDate: null,
        endDate: null
      },
      isSaving: false,
      isComplexBooking: false,
      complexBooking: {
        selectedDays: [],
        breakfast: true,
        refundable: true
      }
    };
  },
  async created() {
    this.getCompanyInfo();

    if (this.checkin) {
      this.reservationMeta.checkin = this.checkin;
    }
    this.reservationMeta.adults = this.adults;

    if (this.nights) {
      this.reservationMeta.nights = this.nights;
    }

    if (this.refundable == "true") {
      this.reservationMeta.refundable = true;
    }

    this.reservationMeta.rooms = this.rooms;
    this.reservationMeta.breakfastIncluded = this.breakfastIncluded;

    let dateRange = CommonHelper.getDateRange(
      this.reservationMeta.checkin,
      this.reservationMeta.nights
    );
    this.startDateString = dateRange.startDateString;
    this.endDateString = dateRange.endDateString;

    let companyInfo = await CommonHelper.getCompanyInfo();
    if (companyInfo && companyInfo.budget) {
      this.budget = companyInfo.budget;
    }

    this.dateRange = [
      moment(dateRange.startDate).toDate(),
      moment(dateRange.endDate).toDate()
    ];
    if (this.isHotelReservation()) {
      this.loadDetails();
    } else {
      setTimeout(() => {
        this.loadMap({
          latitude: this.latitude,
          longitude: this.longitude
        });
      }, 1000);
    }
  },

  computed: {
    numberOfPersons() {
      const selectedNumber =
        this.selectedEmployees.length + this.selectedUnlistedEmployees.length;
      return selectedNumber;
    }
  },
  methods: {
    initComplexBooking() {
      this.$refs.complexBookingComp.initProcess();
    },
    complexDatesSelected(data) {
      this.complexBooking = data;
      this.isComplexBooking = true;
    },
    handleDate(data) {
      let checkin = moment(data[0]).set("hour", 12);
      let endDate = moment(data[1]).set("hour", 12);

      let nights = endDate.diff(checkin, "days");
      if (nights == 0) nights = 1;

      let dateRange = CommonHelper.getDateRange(moment(checkin), nights);

      this.reservationMeta.nights = parseInt(nights);
      this.reservationMeta.checkin = checkin.format("YYYY-MM-DD");

      this.startDateString = dateRange.startDateString;
      this.endDateString = dateRange.endDateString;
    },
    isHotelReservation() {
      return this.reservationType === "hotel";
    },
    setSelectedOffer(data) {
      this.selectedOfferData = data.offer;
    },
    fetchNewPrices() {
      if (this.reservationType !== "hotel") {
        return;
      }
      this.showEditableFields = false;
      this.fetchedFeedbackId = null;
      let query = Object.assign({}, this.$route.query);
      delete query.feedbackId;
      this.$router.replace({ query });
      this.isPricesLoading = true;
      this.selectedOfferData = null;
      this.loadDetails(true);
    },
    async loadDetails(clearFeedbackId = false) {
      this.isLoading = true;

      let postData = {};

      postData.checkin = moment(this.reservationMeta.checkin).format(
        "YYYY-MM-DD"
      );
      postData.nights = parseInt(this.reservationMeta.nights);
      postData.rooms = this.rooms == "" ? 1 : this.rooms;
      if (this.refundable || this.refundable === "false") {
        postData.refundable = false;
      } else {
        postData.refundable = true;
      }
      postData.adults = 1;
      if (this.numberOfPersons > 0) {
        postData.adults = this.numberOfPersons;
      }

      if (this.fetchedFeedbackId) {
        postData.feedbackId = this.fetchedFeedbackId;
      } else if (this.feedbackId) {
        if (this.feedbackId != "false" && this.feedbackId != "true") {
          postData.feedbackId = this.feedbackId;
        }
      }

      if (clearFeedbackId) {
        postData.feedbackId = null;
      }
      // eslint-disable-next-line no-undef
      let url = `${this.AVAILABILITY_API_URL}/hotel/gethotelwithprices/${this.hotelId}`;

      const { status, data } = await this.axios.post(url, postData);
      if (status < 400) {
        this.fetchedFeedbackId = data.feedbackId;
        if (data.progress < 95) {
          setTimeout(() => {
            this.loadDetails();
          }, 700);
        } else {
          if (this.isPricesLoading) {
            this.details.suppliers = data.suppliers;
            this.isPricesLoading = false;
          } else {
            this.details = { ...data };
          }
          this.isLoading = false;
          setTimeout(() => {
            this.loadMap(data);
          }, 300);
        }
      } else {
        this.showNotification = true;
        this.notification.title = "Er ging iets mis";
        this.notification.message =
          "Er gaat iets mis op de server. Probeer het later opnieuw.";
        this.notification.level = "danger";
        this.notification.show = true;
        this.isLoading = false;
      }
    },
    loadMap(location) {
      mapboxgl.accessToken = this.MAPBOX_ACCESS_TOKEN;
      let mapCenter = [location.longitude, location.latitude];
      const map = new mapboxgl.Map({
        container: "details-map",
        style: this.MAPBOX_STYLE,
        center: mapCenter,
        zoom: this.isHotelReservation() ? 15 : 10
      });

      new mapboxgl.Marker()
        .setLngLat([location.longitude, location.latitude])
        .addTo(map);
    },

    setValue(data) {
      this.customFieldFormData[data.field] = data.value;
    },
    async getCompanyInfo() {
      this.companyInfo = await CommonHelper.getCompanyInfo();

      this.companyInfo.employeeIsRequired =
        this.companyInfo.userSettings.canBookOnBehalfOfOthers &&
        (this.companyInfo.allowUnlistedEmployees ||
          this.companyInfo.allowListedEmployees);

      this.companyInfo.customFields.sort((a, b) => {
        return Number(a.mandatory) > Number(b.mandatory) ? -1 : 1;
      });

      this.businessUnits = await CommonHelper.getBusinessUnits();
    },
    selectBusinessUnit(data) {
      this.selectedBusinessUnit = data.businessUnit;
      this.showBusinessUnitSearch = false;
    },
    removeBusinessUnit() {
      this.selectedBusinessUnit = null;
      this.showBusinessUnitSearch = true;
    },
    addEmployee(data) {
      const employeeAlreadySelected = this.selectedEmployees.find(
        (sE) => sE.id == data.id
      );

      if (!employeeAlreadySelected) {
        this.selectedEmployees.push(data.employee);

        if (this.selectedBusinessUnit == null && data.businessUnit) {
          this.selectBusinessUnit(data);
        }
        this.reservationMeta.rooms = this.numberOfPersons;
        this.fetchNewPrices();
      }
    },
    removeSelectedEmployee(e) {
      this.selectedEmployees = this.selectedEmployees.filter(
        (se) => se.id != e.id
      );
      this.reservationMeta.rooms = this.numberOfPersons;
      this.fetchNewPrices();
    },
    addUnlistedEmployee(data) {
      this.selectedUnlistedEmployees.push(data.employee);
      this.reservationMeta.rooms = this.numberOfPersons;
      this.fetchNewPrices();
    },
    removeUnlistedEmployees(e) {
      this.selectedUnlistedEmployees = this.selectedUnlistedEmployees.filter(
        (uE) => uE.id != e.id
      );
      this.reservationMeta.rooms = this.numberOfPersons;
      this.fetchNewPrices();
    },

    gotoResults() {
      const routeParams = {
        locationPath: this.locationPath,
        checkin: this.reservationMeta.checkin,
        nights: this.reservationMeta.nights,
        adults: this.reservationMeta.adults,
        refundable: this.reservationMeta.refundable,
        rooms: this.reservationMeta.rooms,
        breakfastIncluded: this.reservationMeta.breakfastIncluded
      };
      this.$router.push({
        name: this.PAGES.SearchResult,
        params: routeParams
      });
    },
    getTitle(field) {
      return this.getFieldNameTranslation(field);
    },
    validateForm() {
      this.errorMessages = [];
      this.showNotification = false;

      const businessUnitMandatory = this.companyInfo.businessUnitMandatory;
      const businessUnitIsSelected = !!this.selectedBusinessUnit;

      const employeeIsRequired = this.companyInfo.employeeIsRequired;

      let employeeIsSelected =
        this.selectedUnlistedEmployees.length > 0 ||
        this.selectedEmployees.length > 0;
      let isValid = true;

      if (businessUnitMandatory && !businessUnitIsSelected) {
        isValid = false;
        let message = this.$t("error.businessunitMandatory");
        message = this.tbu(message);
        this.errorMessages.push(message);
      }
      if (employeeIsRequired && !employeeIsSelected) {
        isValid = false;
        const message = this.$t("error.employeeMandatory");
        this.errorMessages.push(message);
      }

      if (!this.selectedOfferData && !this.isComplexBooking) {
        isValid = false;
        const message = this.$t("error.noOffers");
        this.errorMessages.push(message);
      }

      let customFieldsValid = true;
      let invalidCustomFields = [];
      this.companyInfo.customFields.forEach((field) => {
        if (
          ReservationHelper.isFieldMandatory(field, this.customFieldFormData)
        ) {
          let thisFieldHasError = false;
          if (field.type === "checkbox") {
            if (
              !this.customFieldFormData[field.name] ||
              this.customFieldFormData[field.name].length < 1
            ) {
              thisFieldHasError = true;
            }
          } else {
            if (
              !this.customFieldFormData[field.name] ||
              this.customFieldFormData[field.name] == ""
            ) {
              thisFieldHasError = true;
            }
          }
          if (thisFieldHasError) {
            customFieldsValid = false;
            isValid = false;
            invalidCustomFields.push(this.getTitle(field));
          }
        }
      });

      if (!customFieldsValid) {
        this.errorMessages = [...this.errorMessages, invalidCustomFields];
      }
      if (!isValid) {
        let notificationMessage = `${this.$t("error.genericIntro")} <ul>
        <li>- ${this.errorMessages.join("</li><li>- ")}</li></ul>`;

        this.track("hotelReservationErrors", {
          error: this.errorMessages.join(", ")
        });

        this.notification.title = this.$t("error.genericTitle");
        this.notification.message = notificationMessage;
        this.notification.level = "danger";
        this.notification.show = true;

        setTimeout(() => {
          this.notification.show = false;
        }, 10 * 1000);
        return false;
      } else {
        return true;
      }
    },
    async makeReservation() {
      let selectedDates = this.isComplexBooking
        ? this.complexBooking.selectedDates
        : CommonHelper.getDateRange(
            this.reservationMeta.checkin,
            this.reservationMeta.nights
          ).dates;

      let dateRange = CommonHelper.getDateRange(
        this.reservationMeta.checkin,
        this.reservationMeta.nights
      );

      let dataIsValid = this.validateForm();

      if (dataIsValid) {
        this.isSaving = true;
        let notes = this.notes;
        let refundable;
        let breakfastIncluded;
        if (this.isComplexBooking) {
          notes += ` \n 
          SYSTEEMMELDING: Dit is een uitgebreide boeking. Er is gekozen voor ${
            this.complexBooking.breakfast ? "" : "geen"
          } ontbijt en ${
            this.complexBooking.refundable ? "" : "niet"
          } refundable`;
          refundable = this.complexBooking.refundable;
          breakfastIncluded = this.complexBooking.breakfast;
        } else {
          refundable = this.selectedOfferData
            ? this.selectedOfferData.isRefundable
            : this.reservationMeta.refundable;

          breakfastIncluded = this.selectedOfferData
            ? this.selectedOfferData.breakfastIncluded
            : this.reservationMeta.breakfastIncluded;
        }

        const employees = this.selectedEmployees.map((e) => e.id);
        const unlistedEmployees = this.selectedUnlistedEmployees.map((e) => {
          return {
            firstName: e.firstName,
            lastName: e.lastName,
            emailAddress: e.emailAddress
          };
        });
        const businessUnitId = this.selectedBusinessUnit
          ? this.selectedBusinessUnit.id
          : null;

        let saveData = {
          reservationList: [
            {
              customFields: this.customFieldFormData,
              selectedDates: selectedDates,
              businessUnitId: businessUnitId,
              employeeIds: employees,
              notes: notes,
              rooms: this.reservationMeta.rooms,
              guests: unlistedEmployees,
              hotel: {
                id: this.details.hotelId,
                name: this.details.name,
                street: this.details.address,
                postalCode: this.details.postalCode,
                city: this.details.city,
                country: this.details.country
              },
              refundable: refundable,
              breakfastIncluded: breakfastIncluded,
              priceDisplayed: this.selectedOfferData?.price,
              selectedOffer: this.selectedOfferData
            }
          ]
        };
        Object.keys(this.customFieldFormData).forEach((key) => {
          saveData.reservationList[0].customFields[key] =
            this.customFieldFormData[key];
        });

        this.saveData = JSON.stringify(saveData, null, " ");

        this.disableButton = true;

        let url = `${this.AVAILABILITY_API_URL}/reservation`;
        let { data, status } = await this.axios.post(url, saveData);

        if (status == 200) {
          let unlistedEmployeesCount = this.selectedUnlistedEmployees.length;
          let listedEmployeesCount = this.selectedEmployees.length;
          let employeeCount = unlistedEmployeesCount + listedEmployeesCount;

          let trackData = {
            company: this.companyInfo.company.name,
            numberOfRooms: this.reservationMeta.rooms,
            numberOfPersons: employeeCount,
            complexBooking: this.isComplexBooking,
            status: "success",
            errorMessages: null
          };

          if (this.isComplexBooking) {
            trackData.breakfastIncluded =
              this.complexBooking.breakfast ?? false;
            trackData.refundable = this.complexBooking.refundable ?? false;
            trackData.selectedDateCount =
              this.complexBooking.selectedDates.length;
          } else {
            trackData.fromDate = this.reservationMeta.checkin;
            trackData.nights = this.reservationMeta.nights;
            trackData.breakfastIncluded =
              this.selectedOfferData?.breakfastIncluded ?? false;
            trackData.refundable = this.selectedOfferData.refundable ?? false;
          }

          this.track("hotelReservation", trackData);
          this.returnedData = JSON.stringify(data, null, " ");

          this.notification.title = this.$t("ReservationForm.savedTitle");
          this.notification.message = this.$t("ReservationForm.savedMessage");
          this.notification.show = true;

          this.notification.level = "success";
          this.selectedEmployees = [];
          this.selectedUnlistedEmployees = [];
          this.showBusinessUnitSearch = true;
          this.selectedBusinessUnit = null;
          setTimeout(() => {
            this.notification.show = false;
            this.disableButton = false;
          }, 30 * 1000);
        } else {
          this.notification.title = this.$t("ReservationForm.errorTitle");
          this.notification.message = this.$t("ReservationForm.errorMessage");
          this.notification.show = true;
          this.notification.level = "danger";
          this.disableButton = true;
          setTimeout(() => {
            this.notification.show = false;
          }, 30 * 1000);
        }
        this.isSaving = false;
      }
    }
  }
};
</script>
<style scoped>
.loading-container {
  text-align: center;
  height: 80vh;
}
</style>
