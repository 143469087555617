<template>
  <div class="c-favorite-hotels">
    <h2 class="title is-4 has-text-centered" style="width: 100%">
      {{ $t("favoriteHotels") }}
    </h2>

    <p v-if="!hotels || hotels.length == 0">
      {{ $t("favoriteHotelsNoHotels") }}
    </p>
    <div
      v-else
      class="c-favorite-hotels__suggestion"
      v-for="hotel in hotels"
      @click="gotoHotel(hotel)"
      :key="hotel.name"
    >
      <img :src="hotel.thumbnail" />
      <a>{{ hotel.name }} </a>
    </div>
  </div>
</template>

<script>
import commonHelper from "@/helpers/common.helper";
import moment from "moment";

export default {
  data() {
    return {
      hotels: []
    };
  },
  async created() {
    const companyInfo = await commonHelper.getCompanyInfo();

    const hotels = companyInfo.favoriteHotels;

    this.hotels = hotels;
  },
  methods: {
    gotoHotel(hotel) {
      this.track("favoriteHotelClick", {
        hotelName: hotel.name,
        hotelCity: hotel.city,
        hotelPostalCode: hotel.postalCode,
        hotelId: hotel.id,
        hotelStreet: hotel.street
      });

      const fauxDate = moment().add("4", "days").format("YYYY-MM-DD");
      this.$router.push({
        name: this.PAGES.HotelDetail,
        params: {
          //locationPath: hotel.city,
          locationPath: "direct",
          hotelId: hotel.id,
          checkin: fauxDate,
          nights: 1,
          rooms: 1,
          adults: 1
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
div.c-favorite-hotels {
  background: white;
  padding: 2rem;
  box-shadow: -3px 0px 11px 3px #6e6b6b;

  &__suggestion {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
    img {
      width: 50px;
      flex: 1 1 15%;
    }
    a {
      padding-left: 20px;
      flex: 1 1 75%;
    }
  }
}
</style>
