<template>
  <div class="results-list__item__price">
    <div class="results-list__item__price__wrapper" v-if="lowestOffer">
      <span>
        <template v-if="nights === 1">
          {{ toPriceLabel(lowestOffer.price) }}
        </template>
        <template v-else>
          {{ getPricePerNight(lowestOffer) }}
        </template>

        &nbsp;
        <i
          v-if="isOverBudget()"
          style="color: orange"
          :title="$t('warningOverBudget', { budget })"
          class="fa-solid fa-triangle-exclamation"
        ></i>
      </span>
      <span
        class="c-direct-contracted-label c-tooltip"
        v-if="isRecommendedHotel(hotel)"
      >
        <i class="fa fa-star"></i>
        {{ $t("recommended.hotel") }}
        <i
          class="fas fa-thin fa-money-bill-transfer pl-2"
          :title="
            lowestOffer.isRefundable
              ? $t('offerIsRefundable')
              : $t('offerIsNotRefundable')
          "
        ></i>

        <i
          class="fas fa-solid fa-bread-slice pl-2"
          :title="
            lowestOffer.isBreakfastIncluded
              ? $t('breakfastAvailable')
              : $t('breakfastNotAvailable')
          "
        ></i>
        <div
          class="c-tooltip--content"
          v-html="thtml('recommendedTitle')"
        ></div>
      </span>
      <span v-else>
        <i
          class="fas fa-thin fa-money-bill-transfer pr-3"
          :class="{
            nonAvailable: !lowestOffer.isRefundable
          }"
          :title="
            lowestOffer.isRefundable
              ? $t('offerIsRefundable')
              : $t('offerIsNotRefundable')
          "
        ></i>

        <i
          class="fas fa-solid fa-bread-slice"
          :class="{
            nonAvailable: !lowestOffer.isBreakfastIncluded
          }"
          :title="
            lowestOffer.isBreakfastIncluded
              ? $t('breakfastAvailable')
              : $t('breakfastNotAvailable')
          "
        ></i>
      </span>

      <sub v-if="nights > 1"
        >{{ $t("total") }} {{ toPriceLabel(lowestOffer.price) }} <br
      /></sub>
    </div>
    <div class="buttons has-addons" style="width: 100%">
      <a class="button is-small is-info" @click="gotoHotelDetail">{{
        $t("viewHotel")
      }}</a>
      <a
        v-if="lowestOffer"
        title="Direct reserveren"
        class="button is-small is-primary"
        @click="gotoHotelReservation"
      >
        <i class="fa fa-bolt"></i>
      </a>
    </div>
  </div>
</template>
<script>
import HotelHelper from "@/helpers/hotel.helper.js";
import CommonHelper from "@/helpers/common.helper.js";

export default {
  name: "PriceLabel",
  methods: {
    getPrice(offer) {
      return HotelHelper.getPrice(offer);
    },
    getPricePerNight(offer) {
      return HotelHelper.getPricePerNight(offer, this.nights);
    },
    gotoHotelDetail() {
      this.$emit("gotoDetail");
    },
    gotoHotelReservation() {
      this.$emit("gotoReservation");
    },

    pricePerNight() {
      if (this.nights == 1) {
        return this.lowestOffer.price;
      } else {
        return this.getPricePerNight(this.lowestOffer);
      }
    },
    isOverBudget() {
      if (!this.budget || this.budget == null) {
        return false;
      }
      const pricePerNight = HotelHelper.getBarePricePerNight(
        this.lowestOffer,
        this.nights
      );
      return pricePerNight > this.budget;
    }
  },
  async created() {
    const companyInfo = await CommonHelper.getCompanyInfo();
    this.budget = companyInfo.budget;
  },
  data() {
    return {
      lowestOffer: null,
      budget: null
    };
  },
  watch: {
    "hotel.suppliers": {
      handler(newValue) {
        let priceSummary = HotelHelper.getPriceSummary(newValue);
        this.lowestOffer = priceSummary.lowestOffer;
      },
      immediate: true
    }
  },
  props: {
    hotel: {},
    adults: String,
    rooms: String,
    nights: [String, Number]
  }
};
</script>
