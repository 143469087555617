// @ts-check
import axios from "axios";
import CommonHelper from "@/helpers/common.helper.js";

// This method returns the price summary for a hotel, including the lowest offers with specific conditions (breakfast, refundable)
const getPriceSummary = (suppliers = []) => {
  const suppliersWithOffers = suppliers.filter(
    (s) => s.offers && s.offers.length
  );

  suppliersWithOffers.forEach((s) => {
    s.offers?.forEach((o) => {
      o.supplierName = s.name;
    });
  });
  const allOffers = suppliersWithOffers.map((s) => s.offers).flat();
  let lowestOffer = null;
  const offersCount = allOffers.length;
  if (offersCount > 0) {
    lowestOffer = getCheapestOffer(allOffers);
  }
  // The blueprint for the return object
  let lowestOffers = {
    refundable: {
      breakfastIncluded: null,
      breakfastNotIncluded: null
    },
    nonRefundable: {
      breakfastIncluded: null,
      breakfastNotIncluded: null
    }
  };
  // Get all refundable offers
  const refundableOffers = allOffers.filter((offer) => offer.isRefundable);
  // get the ones with and without breakfast
  const refundableOffersWithBreakfast = refundableOffers.filter(
    (offer) => offer.isBreakfastIncluded
  );
  const refundableOffersWithoutBreakfast = refundableOffers.filter(
    (offer) => !offer.isBreakfastIncluded
  );

  // check for both types of offers if they exist and set the cheapest one
  if (refundableOffersWithBreakfast.length > 0) {
    lowestOffers.refundable.breakfastIncluded = getCheapestOffer(
      refundableOffersWithBreakfast
    );
  }
  if (refundableOffersWithoutBreakfast.length > 0) {
    lowestOffers.refundable.breakfastNotIncluded = getCheapestOffer(
      refundableOffersWithoutBreakfast
    );
  }
  // sames as above
  const nonRefundableOffers = allOffers.filter((offer) => !offer.isRefundable);
  const nonRefundableOffersWithBreakfast = nonRefundableOffers.filter(
    (offer) => offer.isBreakfastIncluded
  );
  const nonRefundableOffersWithoutBreakfast = nonRefundableOffers.filter(
    (offer) => !offer.isBreakfastIncluded
  );

  if (nonRefundableOffersWithBreakfast.length > 0) {
    lowestOffers.nonRefundable.breakfastIncluded = getCheapestOffer(
      nonRefundableOffersWithBreakfast
    );
  }
  if (nonRefundableOffersWithoutBreakfast.length > 0) {
    lowestOffers.nonRefundable.breakfastNotIncluded = getCheapestOffer(
      nonRefundableOffersWithoutBreakfast
    );
  }

  return {
    lowestOffers,
    suppliersWithOffers,
    offersCount,
    lowestOffer
  };
};

// return the cheapest offer from a list of offers
const getCheapestOffer = (offers) => {
  return offers.sort((a, b) => (a.price >= b.price ? 1 : -1))[0];
};

// This method returns all hotels with offers
const getHotelsWithOffers = (hotels) => {
  let hotelsWithOffers = hotels.filter((hotel) => {
    return hotelHasOffers(hotel);
  });

  return hotelsWithOffers;
};

const getPrice = (offer) => {
  return getFormattedPrice(offer.price);
};

const getPricePerNight = (offer, nights) => {
  if (offer && offer.price) {
    let pricePerNight = offer.price / nights;
    return getFormattedPrice(pricePerNight);
  }

  return null;
};
const getBarePricePerNight = (offer, nights) => {
  if (offer && offer.price) {
    let pricePerNight = offer.price / nights;
    return pricePerNight;
  }

  return null;
};

// the method queries the API for available hotels given the user input
const getHotelPrices = async (
  hotelId,
  hotelName,
  locationPath,
  checkin,
  nights,
  adults,
  rooms
) => {
  const url = `${CommonHelper.getApiURL()}/hotel/getwithpricesbygeopath`;
  let progress = 0,
    hotelData;

  let searchData = {
    page: 1,
    isoLanguage: "nl",
    locationPath: locationPath,
    hotelName: hotelName,
    amount: 25,
    radius: -1,
    stars: [],
    adults: adults,
    rooms: rooms,
    refundable: true,
    checkin: checkin,
    nights: nights,
    reviewScore: [],
    facilities: [],
    feedbackId: null
  };

  // The API call is made until the reported progress is 100. This indicates the progress the api makes in fetching prices in the date range
  while (progress < 100) {
    let { data } = await axios.post(url, searchData);
    progress = data.progress;

    searchData.feedbackId = data.feedbackId;

    if (data.progress == 100) {
      hotelData = data.hotels.find((h) => h.hotelId == hotelId);
    } else {
      const waitTime = 1500;
      // Wait waitTime milliseconds before making the next call and check for the updated progress
      await sleep(waitTime);
    }
  }
  return hotelData;
};

// Sleep function to wait for a certain amount of time, used in the getHotelPrices method
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const getFormattedPrice = (price) => {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR"
  }).format(price);
};

// Iterates over all suppliers and checks if there is at least one offer with a valid price
const hotelHasOffers = (hotel) => {
  let hasOffer = false;
  hotel.suppliers = hotel.suppliers.forEach((s) => {
    if (s.offers && s.offers.length) {
      s.offers.forEach((o) => {
        const hasValidPrice = o.price && o.price > 0;
        if (hasValidPrice) {
          hasOffer = true;
        }
      });
    }
  });

  return hasOffer;
};

// Check for a fixed price offer, usually its a preferred hotel
const hasFixedPriceOffer = (suppliers = []) => {
  let hasFixedPriceOffer = false;
  suppliers.forEach((s) => {
    s.offers?.forEach((s) => {
      if (s.isFixedPrice) {
        hasFixedPriceOffer = true;
      }
    });
  });

  return hasFixedPriceOffer;
};

export default {
  hotelHasOffers,
  getPriceSummary,
  getPrice,
  getPricePerNight,
  getBarePricePerNight,
  getHotelsWithOffers,
  getHotelPrices,
  hasFixedPriceOffer
};
