<template>
  <div class="details">
    <div class="details__title px-5">
      <h1>
        {{ $t("meetingReservation.header") }}
      </h1>
    </div>
    <div class="container">
      <div class="columns is-multiline details__content__box">
        <div class="column is-one-third mr-2">
          <GeoLocationSelection @select-location="locationSelected" />
        </div>
        <div class="column is-two-third">
          <h5 class="title is-5">
            {{ $t("selectDatePeriod") }}
          </h5>

          <DateTimeRangeSelection
            @updated-value="updateDateRange"
          ></DateTimeRangeSelection>
        </div>
      </div>
      <MeetingRoomReservationMeta
        @updated-value="updateMetaValues"
      ></MeetingRoomReservationMeta>

      <div class="columns is-multiline details__content__box mb-3">
        <div class="column is-half">
          <template v-if="businessUnits.length > 1">
            <BusinessunitSelection
              :business-units="businessUnits"
              @selected-business-unit="selectBusinessUnit"
            />
          </template>

          <template v-if="selectedBusinessUnit">
            <div
              class="is-flex is-align-items-center is-justify-content-space-between mb-2"
            >
              <b> {{ selectedBusinessUnit.name }}</b>
              <button class="button is-small is-danger">
                <span
                  class="icon is-small is-danger"
                  @click.stop.prevent="removeBusinessUnit()"
                >
                  <i class="fas fa-times"></i>
                </span>
              </button>
            </div>
          </template>
          <template v-for="field in companyInfo.customFields" :key="field.name">
            <CustomField
              :customField="field"
              @setted-value="setValue"
            ></CustomField>
          </template>
        </div>
        <div class="column is-half">
          <label for="notes">{{ $t("ReservationForm.notes") }}</label>
          <div class="control">
            <textarea
              class="textarea"
              v-model="notes"
              id="notes"
              required
              name="notes"
              type="text"
              rows="5"
              :placeholder="$t('notes')"
            ></textarea>
          </div>
        </div>
        <div class="is-flex is-flex-direction-row-reverse column">
          <div class="control is-flex is-align-content-baseline">
            <button
              type="button"
              @click="requestMeetingRoomReservation()"
              class="c-button is-primary is-medium"
              :disabled="isSaving || disableButton"
            >
              {{ treq("requestReservation") }}
            </button>

            <template v-if="isSaving">
              &nbsp;&nbsp;&nbsp;
              <img width="50" height="50" src="@/assets/images/loader.svg" />
            </template>
          </div>
        </div>
      </div>
      <div v-if="saveData && 2 == 3" class="details__content__box">
        <pre>{{ saveData }}</pre>
      </div>
    </div>
    <NotificationMessage
      :level="notification.level"
      :title="notification.title"
      :message="notification.message"
      :show="notification.show"
      @toggle="toggleNotification"
    />
  </div>
</template>

<script>
import MeetingRoomReservationMeta from "@/components/reservationform/MeetingRoomReservationMeta";
import BusinessunitSelection from "@/components/reservationform/BusinessunitSelection.vue";
import DateTimeRangeSelection from "@/components/reservationform/DateTimeRangeSelection";
import GeoLocationSelection from "@/components/reservationform/GeoLocationSelection";
import CommonHelper from "@/helpers/common.helper";
import CustomField from "@/components/reservationform/CustomField.vue";

import moment from "moment";

export default {
  components: {
    MeetingRoomReservationMeta,
    BusinessunitSelection,
    DateTimeRangeSelection,
    GeoLocationSelection,
    CustomField
  },
  async created() {
    this.businessUnits = await CommonHelper.getBusinessUnits();
    this.companyInfo = await CommonHelper.getCompanyInfo();
  },
  name: "MeetingRoomReservation",
  data() {
    return {
      selectedBusinessUnit: null,
      disableButton: false,
      businessUnits: [],
      companyInfo: {},
      customFieldFormData: [],
      notes: "",
      location: "",
      dateRange: {
        startDate: moment().add("1", "days").toDate(),
        endDate: moment().add("1", "days").toDate(),
        startTime: "09:00",
        endTime: "17:00"
      },
      isSaving: false,
      metaValues: {},
      saveData: ""
    };
  },
  methods: {
    setValue(data) {
      this.customFieldFormData[data.field] = data.value;
    },
    validateForm() {
      this.errorMessages = [];
      this.showNotification = false;

      const businessUnitMandatory = this.companyInfo.businessUnitMandatory;
      const businessUnitIsSelected = !!this.selectedBusinessUnit;

      let isValid = true;

      if (businessUnitMandatory && !businessUnitIsSelected) {
        isValid = false;
        let message = this.$t("error.businessunitMandatory");
        message = this.tbu(message);
        this.errorMessages.push(message);
      }

      if (!this.location) {
        isValid = false;
        let message = this.$t("error.locationMandatory");

        this.errorMessages.push(message);
      }

      let customFieldsValid = true;
      let invalidCustomFields = [];
      this.companyInfo.customFields.forEach((field) => {
        if (field.mandatory) {
          let thisFieldHasError = false;
          if (field.type === "checkbox") {
            if (
              !this.customFieldFormData[field.name] ||
              this.customFieldFormData[field.name].length < 1
            ) {
              thisFieldHasError = true;
            }
          } else {
            if (
              !this.customFieldFormData[field.name] ||
              this.customFieldFormData[field.name] == ""
            ) {
              thisFieldHasError = true;
            }
          }
          if (thisFieldHasError) {
            customFieldsValid = false;
            isValid = false;
            invalidCustomFields.push(this.getTitle(field));
          }
        }
      });

      if (!customFieldsValid) {
        let message = this.$t("error.customFields");
        message += ` (${invalidCustomFields.join(", ")})`;
        this.errorMessages.push(message);
      }

      if (!isValid) {
        let notificationMessage = `${this.$t("error.genericIntro")} <ul>
        <li>- ${this.errorMessages.join("</li><li>- ")}</li></ul>`;

        this.notification.title = this.$t("error.genericTitle");
        this.notification.message = notificationMessage;
        this.notification.level = "danger";
        this.notification.show = true;

        setTimeout(() => {
          this.notification.show = false;
        }, 10 * 1000);
        return false;
      } else {
        return true;
      }
    },
    async requestMeetingRoomReservation() {
      let dataIsValid = this.validateForm();

      if (dataIsValid) {
        this.isSaving = true;
        this.disableButton = true;

        let postData = {
          ...this.metaValues,
          dateRange: this.dateRange,
          location: this.location,
          notes: this.notes,
          businessUnit: this.selectedBusinessUnit?.id,
          customFields: {}
        };

        Object.keys(this.customFieldFormData).forEach((key) => {
          postData.customFields[key] = this.customFieldFormData[key];
        });
        this.saveData = JSON.stringify(postData, null, " ");

        let url = `${this.AVAILABILITY_API_URL}/reservation/meetingroomreservation`;
        let { status, data } = await this.axios.post(url, postData);
        if (status == 200) {
          this.notification.title = this.$t("meetingReservation.savedTitle");
          this.notification.message = this.$t(
            "meetingReservation.savedMessage"
          );
          this.isSaving = false;
          setTimeout(() => {
            this.disableButton = false;
          }, 10 * 1000);
          this.notification.show = true;
          this.notification.level = "info";
          setTimeout(() => {
            this.notification.show = false;
          }, 10 * 1000);
        } else {
          this.isSaving = false;
          this.notification.title = this.$t("meetingReservation.errorTitle");
          this.notification.message = this.$t(
            "meetingReservation.errorMessage"
          );
          this.notification.show = true;
          this.notification.level = "danger";
          setTimeout(() => {
            this.notification.show = false;
          }, 10 * 1000);
        }
      }
    },
    locationSelected(data) {
      this.location = data;
    },
    getTitle(field) {
      return this.getFieldNameTranslation(field);
    },
    updateDateRange(data) {
      this.dateRange = data;
    },
    removeBusinessUnit() {
      this.selectedBusinessUnit = null;
    },
    updateMetaValues(data) {
      this.metaValues = data;
    },
    selectBusinessUnit(data) {
      this.selectedBusinessUnit = data.businessUnit;
      this.showBusinessUnitSearch = false;
    }
  }
};
</script>

<style scoped>
.details__title {
  background: url("@/assets/images/meetingroom.jpeg") center center no-repeat;
  background-size: cover;
}
</style>
