<template>
  <div class="details hoteldetail">
    <div @click="showMap" class="details__title">
      <div id="details-map" ref="detailsMap" class="details__map"></div>
      <div class="wrapper" v-if="details">
        <span
          class="is-flex is-justify-content-space-between is-align-content-baseline is-flex-direction-row"
        >
          <button
            @click.stop="gotoResults()"
            class="button is-primary is-small"
          >
            {{
              this.locationPath === "direct"
                ? $t("backToHome")
                : $t("backToSearch")
            }}
          </button>
        </span>
      </div>
    </div>

    <div
      @click="hideMap"
      class="details__content__popup__map__outer-wrapper"
      :class="{ open: mapIsVisible }"
    >
      <div class="details__content__popup__map">
        <div id="popup-map" ref="popupMap" class="popup__map"></div>
      </div>
    </div>
    <div class="details__content container loading-container" v-if="!isLoaded">
      <h3 class="title is-3">{{ $t("hotelLoading") }}</h3>
      <br />
      <img width="50" height="60" src="@/assets/images/loader.svg" />
    </div>

    <div
      class="details__content container"
      v-if="isLoaded && details"
      style="position: relative; top: -50px"
    >
      <div class="columns is-align-items-flex-start is-multiline">
        <div
          id="hotel-details-header"
          class="details__content__box is-flex is-justify-content-space-between is-align-content-space-between is-align-items-center"
        >
          <div style="min-width: 260px">
            <ReviewsSummary :score="getRating()" :amount="getRatingCount()" />
            <div class="mt-5 pt-2" v-if="getAmenities()">
              <AmenitiesLabel :amenities="getAmenities().popularAmenities" />
            </div>
          </div>
          <span
            class="details__content__hotel-name is-size-5 is-size-6-touch has-text-centered"
            style=""
          >
            <span>
              {{ details?.name }}
            </span>
            <span
              class="c-tooltip"
              v-if="details.hasGreenKey"
              style="margin-top: 3px; margin-left: 6px"
            >
              <img
                width="35"
                height="50"
                src="@/assets/images/greenkey-nobg.png"
              />
              <span class="c-tooltip--content">
                {{ $t("hasGreenKey") }}
              </span>
            </span>
            <span
              class="c-direct-contracted-label mx-3 c-tooltip"
              v-if="isRecommendedHotel(details)"
            >
              <i class="fa fa-star"></i>
              {{ $t("recommended.hotel") }}
              <i
                class="fas fa-thin fa-money-bill-transfer pl-2"
                :title="$t('offerIsRefundable')"
              ></i>

              <i
                class="fas fa-solid fa-bread-slice pl-2"
                :title="$t('breakfastAvailable')"
              ></i>
              <div class="c-tooltip--content">
                {{ thtml("recommendedTitle") }}
              </div>
            </span>
            <HotelStars v-if="details?.stars" :stars="details?.stars" />
          </span>
          <div
            class="details__content__address is-size-7 has-text-centered-touch m-x"
          >
            <a target="_blank" :href="getGoogleMapsLink()"
              >{{ details.address }} {{ details.city }}</a
            >
            <a
              @click="showMap"
              title="Bekijk hotel op kaart"
              class="maps-trigger"
              href="#"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                  d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                />
              </svg>
            </a>
            <div v-if="details?.content?.locationAttribute">
              <br class="is-hidden-touch" />
              {{
                details?.content?.locationAttribute.distanceFromCityCenter
                  .distance
              }}
              {{
                details?.content?.locationAttribute.distanceFromCityCenter.unit
              }}
              {{ $t("distanceFromCityAbs") }}
            </div>
          </div>
        </div>
        <div class="is-full column">
          <div
            class="mb-5"
            style="padding: 0 10%"
            v-if="details?.content?.images"
          >
            <HotelPictures
              :pictures="details?.content?.images"
              :hero="details.content?.hero"
              :hotelName="details.name"
            />
          </div>
        </div>
        <div
          class="details__content__box--centered-wrapper"
          v-if="getDescriptions()"
        >
          <div class="details__content__box content">
            <p v-html="getDescriptions().propertyDescription"></p>
            <p v-html="getDescriptions().areaDescription"></p>
          </div>
        </div>
      </div>
      <div class="columns details__content--container">
        <div class="is-three-fifths column" v-if="details?.content">
          <div class="details__content__box content" v-if="getAmenities()">
            <amenities-list
              :amenities-list="getAmenities().propertyAmenities"
              :title="$t('hotelAmenities')"
            />
          </div>

          <div
            class="details__content__box content"
            v-if="
              (getPolicies() && getPolicies().childrenAndExtraBedPolicy) ||
              (getAmenities() && getAmenities().cleanliness) ||
              (getPolicies() && getPolicies().knowBeforeYouGo)
            "
          >
            <ContentList
              :title="'expedia.childrenAndExtraBedPolicy'"
              v-if="getPolicies() && getPolicies().childrenAndExtraBedPolicy"
              :content-list="getPolicies().childrenAndExtraBedPolicy"
            />

            <ContentList
              :title="'expedia.cleanlinessAndSafety'"
              :content-list="getAmenities().cleanliness"
              v-if="getAmenities() && getAmenities().cleanliness"
            />

            <ContentList
              :title="'expedia.knowBeforeYouGo'"
              :content-list="getPolicies().knowBeforeYouGo"
              v-if="getPolicies() && getPolicies().knowBeforeYouGo"
            />
          </div>
          <div
            class="details__content__box content"
            v-if="
              getPolicies() &&
              (getPolicies().specialInstructions || getPolicies().petPolicy)
            "
          >
            <ContentList
              :title="'expedia.specialInstructions'"
              :content-list="getPolicies().specialInstructions"
              v-if="getPolicies().specialInstructions"
            />
            <ContentList
              :title="'expedia.petPolicy'"
              :content-list="getPolicies().petPolicy"
              v-if="getPolicies().petPolicy"
            />
          </div>
        </div>
        <div class="is-three-fifths column" v-else>
          <p>{{ $t("noHotelContent") }}</p>
        </div>
        <div class="is-two-fifths column">
          <div
            class="details__content__box content"
            v-if="details?.content && getAmenities()"
          >
            <amenities-list
              :flat-amenities-list="getAmenities().popularAmenities"
              :title="$t('popularAmenities')"
            />
          </div>
          <div class="details__content__box content" v-if="details.hasGreenKey">
            <h3>
              <img
                width="40"
                height="50"
                src="@/assets/images/greenkey-nobg.png"
              />
              {{ $t("hasGreenKeyTitle") }}
            </h3>
            <p>
              {{ $t("hasGreenKeyExplanation") }}
            </p>
            <a
              style="text-decoration: underline"
              href="https://www.greenkey.nl/"
              target="_blank"
              >{{ $t("moreInfo") }}</a
            >
          </div>

          <DetailPrice
            @click="gotoReservationForm()"
            v-if="details"
            :hotel="details"
            :checkin="checkin"
            :nights="nights"
            :adults="adults"
            :rooms="rooms"
            :isDirect="isDirect"
            :breakfastIncluded="breakfastIncluded"
          />
          <div
            v-if="
              getPolicies() &&
              getPolicies().paymentPolicy &&
              getPolicies().paymentPolicy?.optionalExtras?.length > 0
            "
            class="details__content__box"
          >
            <p
              class="mt-3"
              v-html="
                getPolicies().paymentPolicy?.optionalExtras?.join('<br />')
              "
            ></p>
          </div>
          <div
            v-if="details?.content && getPolicies() != null"
            class="details__content__box content"
          >
            <h6 class="title is-6" v-html="$t('inOutCheckTimes')"></h6>
            <br />
            <p
              v-if="getPolicies().checkInPolicy"
              v-html="getPolicies().checkInPolicy.join('<br />')"
            ></p>
            <p
              class="mt-3"
              v-if="getPolicies().checkInInstructions"
              v-html="getPolicies().checkInInstructions.join('<br />')"
            ></p>
            <p
              class="mt-3"
              v-if="getPolicies().checkOutPolicy"
              v-html="getPolicies().checkOutPolicy.join('<br />')"
            ></p>
          </div>
          <div
            class="details__content__box content"
            v-if="getAmenities() && getAmenities().roomAmenities"
          >
            <amenities-list
              :amenities-list="getAmenities().roomAmenities"
              :title="$t('roomAmenities')"
            />
          </div>
        </div>
      </div>
    </div>
    <NotificationMessage
      :level="notification.level"
      :autohide="false"
      :title="notification.title"
      :message="notification.message"
      :show="notification.show"
      @toggle="toggleNotification"
    />
    <DetailPriceBar
      @click="gotoReservationForm()"
      v-if="details"
      :hotel="details"
      :checkin="checkin"
      :nights="nights"
      :adults="adults"
      :rooms="rooms"
      :breakfastIncluded="breakfastIncluded"
    />
    <DetailReviews
      v-if="details && details.content && 2 == 3"
      :content="details?.content"
    />
  </div>
</template>

<script>
// eslint-disable no-undef
import mapboxgl from "!mapbox-gl";
import HotelPictures from "./components/hoteldetail/HotelPictures.vue";

import HotelHelper from "@/helpers/hotel.helper.js";
import DetailPrice from "./components/hoteldetail/DetailPrice.vue";
import DetailPriceBar from "./components/hoteldetail/DetailPriceBar.vue";
import HotelStars from "./components/hoteldetail/HotelStars.vue";
import ReviewsSummary from "./components/hoteldetail/ReviewsSummary.vue";
import AmenitiesList from "./components/hoteldetail/AmenitiesList.vue";
import AmenitiesLabel from "./components/searchresult/AmenitiesLabel.vue";
import MapsHelper from "@/helpers/maps.helper.js";
import DetailReviews from "@/components/hoteldetail/DetailReviews";
import ContentList from "./components/hoteldetail/ContentList.vue";
export default {
  components: {
    HotelStars,
    ContentList,
    DetailReviews,
    ReviewsSummary,
    AmenitiesList,
    DetailPrice,
    AmenitiesLabel,
    HotelPictures,
    DetailPriceBar
  },
  props: {
    locationPath: {
      type: String,
      default: ""
    },
    hotelId: {
      type: String,
      default: ""
    },
    refundable: {
      type: String,
      default: "true"
    },
    greenKey: {
      type: String,
      default: "true"
    },
    checkin: {
      type: String,
      default: null
    },
    adults: {
      type: String,
      default: "1"
    },
    rooms: {
      type: String,
      default: "1"
    },
    feedbackId: {
      type: String,
      default: null
    },
    nights: {
      type: String,
      default: null
    },
    breakfastIncluded: {
      type: String,
      default: "false"
    }
  },
  data() {
    return {
      isLoaded: false,
      priceData: {},
      fetchedFeedbackId: null,
      details: null,
      index: 0,
      // Map
      map: null,
      popupMap: null,
      mapIsVisible: false
    };
  },
  name: "HotelDetail",
  mounted() {
    mapboxgl.accessToken = this.MAPBOX_ACCESS_TOKEN;
    window.scrollTo(0, 0);
  },
  created() {
    this.loadDetails();
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
    if (this.popupMap) {
      this.popupMap.remove();
    }
  },

  methods: {
    getSummaries() {
      return this.details.content?.summaries
        ? this.details.content?.summaries[this.languageIso.toLowerCase()]
        : null;
    },
    getAmenities() {
      return this.details?.content?.amenities
        ? this.details?.content?.amenities[this.languageIso.toLowerCase()]
        : null;
    },
    getPolicies() {
      return this.details.content?.policies
        ? this.details.content?.policies[this.languageIso.toLowerCase()]
        : null;
    },
    getDescriptions() {
      return this.details.content?.descriptions
        ? this.details.content?.descriptions[this.languageIso.toLowerCase()]
        : null;
    },
    getRating() {
      return this.details.reviewScore
        ? parseFloat(this.details.reviewScore).toPrecision(2)
        : "0";
    },
    getRatingCount() {
      return parseInt(this.details.reviewAmount ?? 0);
    },
    async loadPrices() {
      return;
    },
    getFormattedPrice(offer) {
      return HotelHelper.getPrice(offer);
    },

    getGoogleMapsLink() {
      return `https://www.google.com/maps/@${this.details.latitude},${this.details.longitude},120m/data=!3m1!1e3`;
    },
    gotoReservationForm() {
      if (this.hasOffer) {
        this.track("gotoHotelReservationHotelDetail", {
          locationPath: this.locationPath,
          hotelId: this.details.hotelId,
          checkin: this.checkin,
          nights: this.nights,
          greenKey: this.greenKey,
          adults: this.adults || 1,
          rooms: this.rooms || 1,
          refundable: this.refundable || true,
          feedbackId: this.feedbackId,
          breakfastIncluded: this.breakfastIncluded
        });
        this.$router.push({
          name: this.PAGES.HotelReservationForm,
          params: {
            locationPath: this.locationPath,
            hotelId: this.details.hotelId,
            checkin: this.checkin,
            nights: this.nights,
            greenKey: this.greenKey,
            adults: this.adults || 1,
            rooms: this.rooms || 1,
            refundable: this.refundable || true,
            feedbackId: this.feedbackId,
            breakfastIncluded: this.breakfastIncluded
          }
        });
      }
    },
    gotoResults() {
      if (this.locationPath === "direct") {
        this.$router.push({
          name: this.PAGES.LandingPage
        });
      } else {
        this.$router.push({
          name: this.PAGES.SearchResult,
          params: {
            locationPath: this.locationPath,
            checkin: this.checkin,
            nights: this.nights,
            greenKey: this.greenKey,
            refundable: this.refundable || true,
            adults: this.adults || 1,
            rooms: this.rooms || 1,
            feedbackId: this.feedbackId
          }
        });
      }
    },
    hideMap() {
      this.mapIsVisible = false;
    },
    async loadDetails() {
      // eslint-disable-next-line no-undef
      let url = `${this.AVAILABILITY_API_URL}/hotel/gethotelwithprices/${this.hotelId}`;
      let postData = {};
      postData.rooms = 1;

      if (this.checkin && this.nights) {
        postData.checkin = this.checkin;
        postData.nights = this.nights;
      }
      postData.feedbackId = "";
      if (this.fetchedFeedbackId != -1) {
        if (this.fetchedFeedbackId) {
          postData.feedbackId = this.fetchedFeedbackId;
        } else if (this.feedbackId) {
          postData.feedbackId = this.feedbackId;
        }
      }

      postData.refundable = this.refundable == "true";
      postData.rooms = this.rooms || 1;
      postData.adults = this.adults || 1;

      const { status, data } = await this.axios.post(url, postData);
      if (status < 400) {
        if (data) {
          if (data.feedbackId) {
            this.fetchedFeedbackId = data.feedbackId;
          }
          if (data.progress < 90) {
            setTimeout(() => {
              this.loadDetails();
            }, 600);
          } else {
            this.details = { ...data };
            this.loadPrices();
            this.loadMap(data);
          }
        } else {
          this.fetchedFeedbackId = -1;
          this.loadDetails();
          // handle fetching error
        }
      } else {
        this.showNotification = true;
        this.notification.title = "Er ging iets mis";
        this.notification.message =
          "Er gaat iets mis op de server. Probeer het later opnieuw.";
        this.notification.level = "danger";
        this.notification.show = true;
        this.isLoading = false;
      }
    },
    async showMap() {
      this.mapIsVisible = true;
      await new Promise((resolve) => setTimeout(resolve, 100));
      let mapContainer = this.$refs.popupMap;
      //mapContainer.innerHtml = "";

      this.popupMap = MapsHelper.getNewMap(mapContainer, false);
      const myCenter = [this.details.longitude, this.details.latitude];
      this.popupMap.setCenter(myCenter).setZoom(12);
      this.popupMap.on("load", () => {
        new mapboxgl.Marker()
          .setLngLat(myCenter)

          .addTo(this.popupMap);
      });
    },
    loadMap(location) {
      let mapContainer = this.$refs.detailsMap;
      //mapContainer.innerHtml = "";
      const myCenter = [location.longitude, location.latitude];
      this.map = MapsHelper.getNewMap(mapContainer);
      this.map.setCenter(myCenter).setZoom(11);

      //Set a marker at the searched location
      new mapboxgl.Marker().setLngLat(myCenter).addTo(this.map);

      this.isLoaded = true;
    }
  },
  computed: {
    isDirect() {
      return this.locationPath === "direct";
    },
    hasOffer() {
      let priceSummary = HotelHelper.getPriceSummary(this.details.suppliers);
      const hasOffer = priceSummary.lowestOffer != null;

      return hasOffer;
    },
    hasFixedPriceOffer() {
      return HotelHelper.hasFixedPriceOffer(this.details.suppliers);
    }
  }
};
</script>
<style lang="scss" scoped>
.loading-container {
  text-align: center;
  height: 80vh;
}
@media screen and (min-width: 920px) {
  .content-columns {
    position: relative;
    top: -100px;
  }
}
</style>
