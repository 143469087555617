<template>
  <div>
    <teleport to="#inputSelectionTarget" v-if="result">
      <div class="autocomplete-results">
        <a name="outletItem"></a>
        <ul class="autocomplete-results__result-list">
          <li
            class="autocomplete-results__result-list__item"
            v-if="result && result.length == 0"
          >
            {{ $t("nothingFound") }}
          </li>

          <li
            class="autocomplete-results__result-list__item"
            v-for="item in result"
            :key="item"
            v-on:click="selectResult(item)"
          >
            <span class="autocomplete-results__result-list__icon">
              <i v-if="item.featureCode === 'HTL'" class="fas fa-hotel"></i>
              <i v-else class="fas fa-city"></i>
            </span>
            <span class="autocomplete-results__result-list__name"
              >{{ item.name }} {{ item.admin1 }}, {{ item.country }}
            </span>
          </li>
        </ul>
        <span @click="result = null" class="c-close-foldout">{{
          $t("close")
        }}</span>
      </div>
    </teleport>

    <div class="searchbar__icon">
      <i class="fas fa-location-dot"></i>
    </div>
    <input
      type="text"
      v-bind:name="name"
      v-model="searchInput"
      id="searchInput"
      v-on:keyup="getResults"
      v-on:change="getResults"
      class="searchbar__input"
      :placeholder="$t('location')"
      :class="{ 'searchbar__input--loading': isLoading }"
      autocomplete="off"
    />
  </div>
</template>

<script>
import commonHelper from "@/helpers/common.helper";

export default {
  name: "LocationAutoComplete",
  props: {
    name: String,
    input: String
  },
  emits: ["selectLocation", "hasLocationResult"],
  data() {
    return {
      callId: null,
      isLoading: false,
      result: null,
      searchInput: "",
      lastSearched: null
    };
  },
  methods: {
    async getResults() {
      if (this.searchInput && this.searchInput.length >= 1) {
        if (this.searchInput == this.lastSearched) {
          return;
        }
        const searchInput = this.searchInput;
        if (this.callId) {
          clearTimeout(this.callId);
        }
        this.callId = setTimeout(async () => {
          this.isLoading = true;
          const data = await commonHelper.getLocationsFromSearch(searchInput);
          this.lastSearched = searchInput;
          this.result = data.slice(0, 7);
          this.isLoading = false;
          this.$emit("hasLocationResult", true);
        }, 750);
      } else {
        this.$emit("hasLocationResult", false);
        this.result = [];
      }
    },
    selectResult(locationItem) {
      this.searchInput = locationItem.name;
      this.$emit("hasLocationResult", false);
      this.$emit("selectLocation", locationItem);
      this.result = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete-results__result-list {
  &__icon {
    height: 100%;
    width: 4rem;
    line-height: 1.8rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 -1rem;

    img {
      position: relative;
      height: 100%;
      display: inline-block;
      height: 1.8rem;
    }
  }
  &__name {
    display: block;

    text-align: right;
  }

  &__description {
    font-size: 11pt;
  }
}
</style>
