<template>
  <div class="c-amenities-list is-flex is-justify-content-space-between">
    <span
      v-for="a in primeAmenities"
      class="c-tooltip"
      :class="{ 'c-amenities-list--icon__inactive': !hasAmenity(a) }"
      :key="a.name"
    >
      <i :class="a.icon"></i>

      <span class="c-tooltip--content">
        <template v-if="!hasAmenity(a)">
          <del>{{ $t("expedia." + a.name) }}</del>
        </template>
        <template v-else>
          {{ $t("expedia." + a.name) }}
        </template>
      </span>
    </span>
  </div>
</template>

<script>
import FilterHelper from "@/helpers/filters.helper.js";
export default {
  name: "AmenitiesLabel",
  props: {
    amenities: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      primeAmenities: []
    };
  },
  created() {
    this.primeAmenities = FilterHelper.getPrimeAmenities().filter(
      (a) => a.icon
    );
  },
  methods: {
    hasAmenity(a) {
      if (a.name.indexOf("_AVAILABLE") > -1) {
        let altName = a.name.replace("_AVAILABLE", "_INCLUDED");
        if (a.name.indexOf("PARK") > -1) {
          return (
            this.amenities?.indexOf(a.name) > -1 ||
            this.amenities?.indexOf(altName) > -1 ||
            this.amenities?.indexOf("FREE_PARKING") > -1
          );
        } else {
          return (
            this.amenities?.indexOf(a.name) > -1 ||
            this.amenities?.indexOf(altName) > -1
          );
        }
      } else {
        return this.amenities?.indexOf(a.name) > -1;
      }
    }
  }
};
</script>
