// @ts-check

// Ik heb eigenlijk geen idee waarom dit zo is opgelost, maar het werkt en het is de moeite van het aanpassen niet waard
const getStars = (stars) => {
  let starsArr = [];
  if (stars) {
    if (stars.oneStars) {
      starsArr.push(1);
    }
    if (stars.twoStars) {
      starsArr.push(2);
    }
    if (stars.threeStars) {
      starsArr.push(3);
    }
    if (stars.fourStars) {
      starsArr.push(4);
    }
    if (stars.fiveStars) {
      starsArr.push(5);
    }
  }
  return starsArr;
};
const getReviewScore = (score) => {
  // 1. Create an empty array called reviewScoreArr
  const reviewScoreArr = [];

  // 2. If the score object exists, check each key to see if it is true
  // and if it is, add the corresponding number to the array
  if (score) {
    if (score.five) {
      reviewScoreArr.push(5);
    }
    if (score.six) {
      reviewScoreArr.push(6);
    }
    if (score.seven) {
      reviewScoreArr.push(7);
    }
    if (score.eight) {
      reviewScoreArr.push(8);
    }
    if (score.nine) {
      reviewScoreArr.push(9);
    }
  }

  // 3. Return the completed array
  return reviewScoreArr;
};

// Dit zijn de prime amenities die we willen laten zien in het overzicht.
const getPrimeAmenities = () => {
  let amenities = [];
  amenities.push({
    name: "PARKING_AVAILABLE",
    icon: "fa-solid fa-parking"
  });
  amenities.push({
    name: "RESTAURANT",
    icon: "fa-solid fa-utensils"
  });
  amenities.push({
    name: "BREAKFAST_AVAILABLE",
    icon: "fa-solid fa-bread-slice"
  });
  amenities.push({
    name: "INTERNET",
    icon: "fa-solid fa-wifi"
  });
  amenities.push({
    name: "FRONTDESK_24_HOUR",
    icon: "fa-solid fa-bell-concierge"
  });
  amenities.push({
    name: "FITNESS_EQUIPMENT",
    icon: "fa-solid fa-dumbbell"
  });
  amenities.push({
    name: "LAUNDRY",
    icon: "fa-solid fa-jug-detergent"
  });
  amenities.push({
    name: "BAR",
    icon: "fa-solid fa-martini-glass-citrus"
  });

  amenities.push({
    name: "KITCHEN"
  });
  amenities.push({
    name: "POOL",
    icon: "fa-solid fa-water-ladder"
  });

  /*

  amenities.push({
    name: "PETS",
  });
  */
  amenities.push({
    name: "AC"
  });
  amenities.push({
    name: "FREE_PARKING"
  });
  /*
  amenities.push({
    name: "PARKING_AVAILABLE",
  });*/
  amenities.push({
    name: "AIRPORT_TRANSPORTATION"
  });

  amenities.push({
    name: "SPA"
  });
  // amenities.push({
  //   name: "BUSINESS_SERVICES",
  // });
  amenities.push({
    name: "ROOM_SERVICE"
  });
  /*
  amenities.push({
    name: "GROCERY",
  });
  amenities.push({
    name: "BABYSITTING",
  });
  */
  amenities.push({
    name: "LIVING_AREA"
  });

  amenities.push({
    name: "ELECTRIC_CAR"
  });

  //https://fontawesome.com/search?q=breakfast&o=r

  return amenities;
};
export default {
  getStars,
  getReviewScore,
  getPrimeAmenities
};
