import CommonHelper from "@/helpers/common.helper.js";
import moment from "moment";
import HotelHelper from "@/helpers/hotel.helper.js";
import Datepicker from "@vuepic/vue-datepicker";

const PAGES = CommonHelper.PAGES;
// These methods all get injected into the Vue instance, so its available in every single component
// WARNING: Be careful adding methods here, since they will be available in every single component and can cause conflicts and impact performance
export const mixins = {
  // Datepicker is globally available.
  components: {
    Datepicker
  },
  data() {
    if (!this.$i18n.locale) {
      this.$i18n.locale = "nl";
    }
    // These variables are available in every single component via this.variableName (so this.PAGES, this.language, etc)
    return {
      PAGES: PAGES,
      language: this.$i18n.locale ? this.$i18n.locale : "nl",
      languageIso: this.$i18n.locale ? this.$i18n.locale.toUpperCase() : "NL",
      // eslint-disable-next-line no-undef
      AVAILABILITY_API_URL: CommonHelper.getApiURL(),
      // eslint-disable-next-line no-undef
      MAPBOX_ACCESS_TOKEN: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      // eslint-disable-next-line no-undef
      MAPBOX_STYLE: process.env.VUE_APP_MAPBOX_STYLE,
      // eslint-disable-next-line no-undef
      CDN_URL: process.env.VUE_APP_CDN_URL,
      notification: {
        show: false,
        type: "warning",
        title: "",
        message: ""
      }
    };
  },
  methods: {
    track(event, config) {
      const trackingObject = {
        event: event,
        ...config
      };

      window.dataLayer.push(trackingObject);
    },
    isRecommendedHotel(hotel) {
      return HotelHelper.hasFixedPriceOffer(hotel.suppliers);
    },
    // this method is used to set the query string parameter in the url (i.e. ?name=value)
    setQueryStringParameter(name, value) {
      const params = new URLSearchParams(window.location.search);
      params.set(name, value);
      window.history.replaceState(
        {},
        "",
        decodeURIComponent(`${window.location.pathname}?${params}`)
      );
    },
    toggleNotification(e) {
      this.notification.show = e;
    },
    getHotelNameWithCity(hotel) {
      return hotel.name;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getCompanyInfo() {
      let companyInfo = await CommonHelper.getCompanyInfo();
      if (!companyInfo) {
        throw new Error("Error getting company info");
      }
      localStorage.locale = companyInfo.language ?? "nl";
      this.$i18n.locale = companyInfo.language ?? "nl";
    },
    async injectCustomCss() {
      if (!localStorage.theme) {
        let brandCssUri = `${this.AVAILABILITY_API_URL}/company/brand.css`;
        const { data } = await this.axios.get(brandCssUri);
        if (!data) {
          throw new Error("Error getting brand css");
        } else {
          localStorage.theme = data;
        }
      }
      const styleSheetId = "brandSpecificStyleSheet";
      if (document.getElementById(styleSheetId)) {
        document.getElementById(styleSheetId).remove();
      }
      let styleElement = document.createElement("style");
      let theme = localStorage.theme ? localStorage.theme : "";
      styleElement.textContent = theme;
      styleElement.id = styleSheetId;
      document.body.appendChild(styleElement);
    },
    formatNumber(number) {
      return CommonHelper.formatNumber(number);
    },
    formatDecimals(number) {
      return CommonHelper.formatDecimals(number);
    },
    toPriceLabel(number) {
      return CommonHelper.formatPrice(number);
    },
    formatDate(date) {
      return this.formatMoment(new moment(date));
    },
    formatMoment(momentDate) {
      if (!momentDate || momentDate === "") {
        return "";
      }
      return momentDate.format("DD-MM-YYYY");
    },
    formatDateLabelDay(date) {
      return this.formatMomentLabelDay(new moment(date));
    },
    formatMomentLabelDay(momentDate) {
      if (!momentDate || momentDate === "") {
        return "";
      }

      return `${momentDate.format("dddd")}`;
    },
    formatDateLabel(date) {
      return this.formatMomentLabel(new moment(date));
    },
    formatMomentLabel(momentDate) {
      if (!momentDate || momentDate === "") {
        return "";
      }

      return `${momentDate.format("DD MMMM YYYY")}`;
    },
    getCompanyInfoFromStorage() {
      let companyInfo = CommonHelper.getCompanyInfoSync();
      return companyInfo;
    },
    /*
    The following methods are all related to the reservation form
    */

    getFieldNameTranslation(field) {
      let companyInfo = this.getCompanyInfoFromStorage();
      let customField = companyInfo.customFields.find(
        (f) => f.name === field.name
      );
      if (customField) {
        return this.getTranslationForCustomFieldElement(
          customField.translations
        );
      } else {
        return "";
      }
    },
    getFieldValueTranslation(field, fieldValue) {
      let companyInfo = this.getCompanyInfoFromStorage();
      let customField = companyInfo.customFields.find(
        (f) => f.name === field.name
      );
      if (!customField || typeof customField === "undefined") {
        console.log(
          "No custom field found for",
          field,
          "returning field value",
          fieldValue
        );
        return fieldValue;
      }

      switch (customField.type) {
        case "checkbox":
          return field.value
            .split(",")
            .map((value) => {
              const myOption = customField.options.find(
                (o) => o.value === value
              );
              if (!myOption || typeof myOption === "undefined") {
                console.log(
                  "Couldn't find anything for checkbox",
                  field,
                  fieldValue
                );
                return fieldValue;
              }
              return this.getTranslationForCustomFieldElement(
                myOption.translations
              );
            })
            .join(", ");
        case "textinput":
          return fieldValue;
        default: {
          let relevantOption = customField.options.find(
            (o) => o.value === fieldValue
          );
          if (!relevantOption || typeof relevantOption === "undefined") {
            console.log("Couldn't find anything for", field, fieldValue);
            return fieldValue;
          }
          return this.getTranslationForCustomFieldElement(
            relevantOption.translations
          );
        }
      }
    },
    getTranslationForCustomFieldElement(translations) {
      let translation = translations.find(
        (cF) => cF.languageIso == this.languageIso
      );
      if (translation) {
        return translation.title;
      } else {
        try {
          return translations[0].title;
        } catch (e) {
          console.info("no translation found for in", translations);
          return "";
        }
      }
    },
    getBusinessUnitLabel() {
      let companyInfo = this.getCompanyInfoFromStorage();

      let translations = companyInfo.translations;
      const businessUnitTranslation = translations.find(
        (t) =>
          t.type === "BusinessUnitName" &&
          t.languageIso.toLowerCase() == this.languageIso.toLowerCase()
      );

      if (businessUnitTranslation) {
        return businessUnitTranslation.title;
      } else {
        return this.$t("businessunit");
      }
    },
    //TBU > Translate Business Unit. this translates a key with business unit it (not all companies use the same terminology for business unit)
    tbu(key) {
      let translation = this.$t(key);
      let businessUnitLabel = this.getBusinessUnitLabel();
      if (!businessUnitLabel) {
        return "";
      }

      // Als afdeling in de vertaling staat, dan moet het met een kleine letter beginnen als het niet het eerste woord is
      if (translation.indexOf("BUSINESS_UNIT") > -1) {
        businessUnitLabel =
          businessUnitLabel.charAt(0).toLowerCase() +
          businessUnitLabel.slice(1);
      }
      return translation.replace("BUSINESS_UNIT", businessUnitLabel);
    },
    // Get the request label (boeken/aanvragen buttons). Not every company uses the same terminology for this
    getRequestLabel() {
      let companyInfo = this.getCompanyInfoFromStorage();

      let translations = companyInfo.translations;
      if (!translations || !translations.length) {
        return null;
      }
      const requestTranslation = translations.find(
        (t) => t.type === "BookNow" && t.languageIso == this.languageIso
      );

      if (requestTranslation) {
        return requestTranslation.title;
      } else {
        return null;
      }
    },
    isProd() {
      let env = CommonHelper.getApiEnv();

      return env === "prod";
    },
    // Dit is omdat i18n niet werkt met html in de vertalingen. Dit is een workaround
    // er is een setting bij i18n om de meldingen uit te zetten, maar dat werkt niet
    // of ik krijg het niet voorelkaar. Dusssss zodoende. Er is 1 key die html bevat
    thtml(key) {
      return decodeURI(this.$t(key));
    },
    // Translate request label (boeken/aanvragen buttons)
    treq(key) {
      const requestLabel = this.getRequestLabel();

      if (requestLabel) {
        return requestLabel;
      } else {
        return this.$t(key);
      }
    }
  }
};
