<template>
  <div
    class="results-list__wrapper"
    :class="{
      'results-list__wrapper--collapse': collapseItem
    }"
  >
    <div
      class="results-list__wrapper--thumbnails"
      v-if="hotel.thumbnails && !collapseItem"
      @click="gotoHotelDetail()"
      style="cursor: pointer"
    >
      <div
        v-for="photo in hotel.thumbnails"
        :key="photo"
        :style="{
          'background-image': 'url(' + photo + ')'
        }"
      ></div>
    </div>

    <div class="results-list__wrapper--content">
      <a class="results-list__item__name" :href="hotel.link"
        >{{ getHotelNameWithCity(hotel) }}
        <span
          v-if="hotel.hasGreenKey"
          style="margin-top: 3px; margin-left: 2px"
        >
          <img width="20" height="50" src="@/assets/images/greenkey-nobg.png" />
        </span>
      </a>
      <div style="max-width: 290px" v-if="!collapseItem">
        <AmenitiesLabel :amenities="hotel.amenities" />
      </div>
      <LocationLabel
        :distance="hotel.distance"
        :hotel-city="hotel.city"
        :city="location.name"
        @click="showHotelMarker()"
      />
      <ReviewsLabel
        :score="parseFloat(hotel.reviewScore.toPrecision(2))"
        :amount="hotel.reviewAmount"
        v-if="!collapseItem"
      />
    </div>
    <div class="results-list__wrapper--price">
      <StarsLabel :stars="hotel.stars" v-if="hotel.stars" />
      <PriceLabel
        v-if="done"
        :hotel="hotel"
        :nights="nights"
        @goto-detail="gotoHotelDetail"
        @goto-reservation="gotoHotelReservation"
      />
    </div>
  </div>
</template>

<script>
import StarsLabel from "./StarsLabel.vue";
import AmenitiesLabel from "./AmenitiesLabel.vue";
import LocationLabel from "./LocationLabel.vue";
import ReviewsLabel from "./ReviewsLabel.vue";
import PriceLabel from "./PriceLabel.vue";
import HotelHelper from "@/helpers/hotel.helper.js";

export default {
  components: {
    StarsLabel,
    AmenitiesLabel,
    LocationLabel,
    ReviewsLabel,
    PriceLabel
  },
  data() {
    return {
      cdnUrl: this.CDN_URL,
      lowestOffer: null
    };
  },
  methods: {
    gotoHotelDetail() {
      this.$emit("gotoDetail", this.hotel.hotelId);
    },
    gotoHotelReservation() {
      this.$emit("gotoReservation", this.hotel.hotelId);
    },
    showHotelMarker() {
      this.$emit("showMarker", this.hotel.hotelId);
    }
  },
  computed: {
    collapseItem() {
      return this.lowestOffer == null && this.collapseOfferless;
    }
  },
  watch: {
    "hotel.suppliers": {
      handler(newValue) {
        let priceSummary = HotelHelper.getPriceSummary(newValue);
        this.lowestOffer = priceSummary.lowestOffer;
      },
      immediate: true
    }
  },
  name: "ResultItem",
  emits: ["gotoDetail", "gotoReservation", "showMarker"],
  props: {
    hotel: {},
    location: {},
    locationPath: String,
    collapseOfferless: Boolean,
    done: Boolean,
    feedbackId: String,
    checkin: String,
    adults: [String, Number],
    rooms: [String, Number],
    nights: [String, Number]
  }
};
</script>
