<template>
  <div class="search">
    <div
      class="search__header details__content__box ml-4 mr-2 mb-3 mt-3"
      :class="{ 'is-flex-direction-column': filtersOpen }"
    >
      <div class="columns is-multiline mt-1 mb-0" @click="toggleFilters()">
        <div class="column is-full--mobile is-one-fifth has-text-centered">
          <a class="c-button c-button--small" v-if="loadingData.progress > 90">
            {{ $t("searchFilters.change") }}
          </a>
        </div>
        <div class="column is-full--mobile">
          <SearchSummary
            :variables="variables"
            :hotelCount="hotelCount"
            :location="location"
          />
        </div>
      </div>
      <SearchFilters
        :location="location"
        :variables="variables"
        :filtersOpen="filtersOpen"
        @toggleFilters="toggleFilters"
        @applyFilters="applyFilters"
        @closeFilters="closeFilters"
        @selectedLocation="selectedLocation"
      />
    </div>
    <div class="search__results" ref="searchResultsContainer">
      <div
        class="error-message has-text-red details__content__box"
        v-if="errorMessage"
      >
        <div class="content">
          <h1 class="title is-2">{{ errorMessage }}</h1>
        </div>
      </div>
      <img
        width="30"
        height="30"
        v-if="loadingData.progress < 1"
        src="@/assets/images/loader.svg"
        class="c-loading-icon"
      />
      <ul class="results-list" v-if="loadingData.progress > 0">
        <!-- li style="text-align: right">
          <label class="checkbox">
            <input type="checkbox" v-model="collapseOfferless" />
            &nbsp; {{ $t("collapseOfferless") }}
          </label>
        </!-->
        <li v-if="hotels?.length > 0" class="results-list__item mt-3 content">
          <AnnotationsList></AnnotationsList>
        </li>
        <li
          v-if="hotels?.length > 0 && isTonightSearch"
          class="results-list__item mt-3 content"
        >
          {{ $t("comingNightNoPrices") }}
        </li>
        <li v-if="hotels?.length < 4 && 1 == 2" class="results-list__item mt-3">
          <CityReservation
            :hotel-count="hotelCount"
            :location="location"
            @goto-city-reservation="gotoCityReservation()"
          />
        </li>
        <li
          v-if="hotels?.length === 0 && !errorMessage"
          class="results-list__item mt-3"
        >
          <h5 class="title is-5">{{ $t("noHotelsFoundTitle") }}</h5>
          <p>
            {{ $t("noHotelsFoundMessage") }}
          </p>
        </li>
        <li
          class="results-list__item"
          v-for="(hotel, $index) in hotels"
          :key="hotel"
          :data-ix="$index"
          :data-score="hotel.irisScore"
          :data-score-factors="hotel.irisScoreFactors"
          :data-hotel-name="hotel.name"
          ref="hotelCards"
          :id="`${hotel.hotelId}`"
        >
          <ResultItem
            :hotel="hotel"
            :location="location"
            @goto-detail="gotoHotelDetail"
            @goto-reservation="gotoHotelReservation"
            @show-marker="showMarker"
            :locationPath="variables.locationPath"
            :feedbackId="feedbackId"
            :collapseOfferless="collapseOfferless"
            :adults="variables.adults"
            :rooms="variables.rooms"
            :checkin="variables.checkin"
            :nights="variables.nights"
            :done="true"
          />
        </li>

        <li
          v-if="hotels?.length > 4 && 1 == 2"
          class="results-list__item mt-3 city-reservation-block"
        >
          <CityReservation
            :location="location"
            @goto-city-reservation="gotoCityReservation()"
          />
        </li>
      </ul>
      <ResultPaging
        v-if="loadingData.progress === 100"
        :pageCount="pageCount"
        :currentPage="variables.page"
        @page="selectPage"
      />
    </div>

    <div id="overview-map" class="search__map">
      <SearchLoading
        :loadingData="loadingData"
        v-show="loadingData.progress !== 100 && !errorMessage"
      />
      <div ref="mapContainer"></div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import ResultItem from "./components/searchresult/ResultItem.vue";
import ResultPaging from "./components/shared/ResultPaging.vue";
import SearchFilters from "./components/filters/SearchFilters.vue";
import FilterHelper from "@/helpers/filters.helper.js";
import MapsHelper from "@/helpers/maps.helper.js";
import SearchSummary from "./components/searchresult/SearchSummary.vue";
import SearchLoading from "./components/searchresult/SearchLoading.vue";
import CityReservation from "./components/searchresult/CityReservation";
import AnnotationsList from "./components/searchresult/AnnotationsList";
import moment from "moment";

export default {
  components: {
    ResultItem,
    SearchSummary,
    SearchLoading,
    ResultPaging,
    SearchFilters,
    AnnotationsList,
    CityReservation
  },
  name: "SearchResult",
  data() {
    return {
      timeoutCode: null,
      collapseOfferless: false,
      errorMessage: null,
      filtersOpen: false,
      location: null,
      name: null,
      coordinates: null,
      hotels: null,
      hotelCount: 0,
      pageCount: 0,
      map: null,
      filters: [],
      feedbackId: null,
      loadingData: {},
      annotations: [],
      hotelMarkers: [],
      bookingRulesHidden: false,
      mapPopup: null,
      isTonightSearch: false,
      variables: {
        page: 1,
        isoLanguage: "nl",
        locationPath: "",
        hotelName: "",
        amount: 25,
        radius: 0,
        stars: [],
        adults: 1,
        rooms: 1,
        refundable: false,
        greenKey: false,
        checkin: new Date(),
        nights: 2,
        reviewScore: [],
        amenities: []
      }
    };
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
  async mounted() {
    const params = { ...this.$route.params };
    this.track("searchResultsPageOpened");

    if (params.checkin) {
      this.variables.checkin = params.checkin;
    }
    if (params.nights) {
      this.variables.nights = params.nights;
    }
    if (params.greenKey) {
      this.variables.greenKey = params.greenKey === "true";
    }
    if (params.adults) {
      this.variables.adults = params.adults;
    }
    if (this.$route.query?.feedbackId) {
      this.variables.feedbackId = this.$route.query?.feedbackId;
    }

    const locationPath = this.$route.params.locationPath;
    this.variables.locationPath = locationPath;
    mapboxgl.accessToken = this.MAPBOX_ACCESS_TOKEN;
    // if ("collapseOfferless" in localStorage) {
    //   this.collapseOfferless = localStorage.collapseOfferless ? true : false;
    // } else {
    //   this.collapseOfferless = false;
    // }
    this.collapseOfferless = true;
    let mapContainer = this.$refs.mapContainer;
    //mapContainer.innerHtml = "";
    this.map = MapsHelper.getNewMap(mapContainer);
    this.loadHotels();
  },
  watch: {
    collapseOfferless(newValue) {
      localStorage.collapseOfferless = newValue;
    }
  },
  methods: {
    openFilters() {
      this.filtersOpen = true;
    },
    closeFilters() {
      this.filtersOpen = false;
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
    },

    applyFilters(filters) {
      this.filtersOpen = false;
      this.filters = filters;
      this.variables.radius = parseInt(filters.radius);
      this.variables.stars = FilterHelper.getStars(filters.stars);
      this.variables.hotelName = filters.hotelName;
      this.variables.refundable = filters.refundable;
      this.variables.greenKey = filters.greenKey;
      this.variables.reviewScore = FilterHelper.getReviewScore(filters.score);
      this.variables.amenities = [];
      for (let key in filters.amenities) {
        if (filters.amenities[key]) {
          this.variables.amenities.push(key);
        }
      }

      if (filters.checkin) {
        this.variables.checkin = moment(filters.checkin).format("YYYY-MM-DD");
      }
      this.variables.nights = filters.nights;
      this.variables.adults = filters.adults;
      this.variables.rooms = filters.rooms;
      this.variables.feedbackId = null;

      this.loadingData = {
        progress: 0,
        hotelCount: 0
      };
      this.hotels = [];
      let selectedFilters = { ...this.variables };
      delete selectedFilters.feedbackId;
      delete selectedFilters.isoLanguage;
      selectedFilters.amenities = selectedFilters.amenities.join(", ");
      selectedFilters.reviewScore = selectedFilters.reviewScore.join(", ");
      selectedFilters.stars = selectedFilters.stars.join(", ");

      this.track("submitSearchFilters", selectedFilters);
      this.loadHotels();
    },

    loadMap(location) {
      const myCenter = [
        location.location.longitude,
        location.location.latitude
      ];
      this.map.setCenter(myCenter);

      this.hotelMarkers = this.hotels.map((hotel) =>
        MapsHelper.getMarkerFromHotel(hotel)
      );
      const hotelSource = MapsHelper.getSource(this.hotelMarkers);
      const layer = MapsHelper.getLayer("hotelLayer", "hotels");
      if (this.map.getSource("hotels")) {
        this.map.removeSource("hotels");
      }

      this.map.addSource("hotels", hotelSource);
      this.map.addLayer(layer);
      if (this.hotelMarkers.length > 1) {
        var bounds = new mapboxgl.LngLatBounds();
        this.hotelMarkers.forEach(function (feature) {
          bounds.extend(feature.geometry.coordinates);
        });

        this.map.fitBounds(bounds);
      }
      const el = document.createElement("div");
      el.className = "searched-marker";
      new mapboxgl.Marker(el).setLngLat(myCenter).addTo(this.map);
      this.map.off("click", "hotelLayer", this.handleMarkerClick);
      this.map.on("click", "hotelLayer", this.handleMarkerClick);
    },
    handleMarkerClick(e) {
      let selectedHotelId = e.features[0].properties.hotelId;
      const hotelCards = this.$refs.hotelCards;
      const selectedHotelCard = hotelCards.find((e) => e.id == selectedHotelId);
      const selectedHotel = this.hotels.find(
        (h) => h.hotelId === selectedHotelId
      );
      this.track("hotelMarkerClickSearchResult", {
        hotelName: selectedHotel.name,
        hotelCity: selectedHotel.city,
        hotelPostalCode: selectedHotel.postalCode,
        hotelId: selectedHotel.hotelId,
        hotelStreet: selectedHotel.street
      });

      this.$refs.searchResultsContainer.scrollTo({
        top: selectedHotelCard.offsetTop - 350,
        left: 0,
        behavior: "smooth"
      });

      selectedHotelCard.classList.add("selected-card");
      setTimeout(() => {
        selectedHotelCard.classList.remove("selected-card");
      }, 5000);

      const coordinates = e.features[0].geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let description = MapsHelper.getPopupDescription(selectedHotel, this.$t);
      if (this.mapPopup) {
        this.mapPopup.remove();
      }
      this.mapPopup = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description);
      this.mapPopup.addTo(this.map);
    },
    selectPage(page) {
      this.track("gotoPageSearchResult", {
        page: page
      });
      this.variables.page = page;

      this.variables.feedbackId = null;

      this.loadHotels();
    },
    gotoHotelDetail(hotelId) {
      if (this.timeoutCode) {
        // Stop polling if detailpage is loading
        clearTimeout(this.timeoutCode);
      }
      this.track("gotoHotelDetailSearchResults", {
        locationPath: this.variables.locationPath,
        hotelId: hotelId,
        checkin: this.variables.checkin,
        nights: this.variables.nights,
        adults: this.variables.adults,
        refundable: this.variables.refundable,
        greenKey: this.variables.greenKey,
        rooms: this.variables.rooms,
        breakfastIncluded:
          this.variables.amenities.includes("BREAKFAST_INCLUDED")
      });
      this.$router.push({
        name: this.PAGES.HotelDetail,
        params: {
          locationPath: this.variables.locationPath,
          hotelId: hotelId,
          checkin: this.variables.checkin,
          nights: this.variables.nights,
          adults: this.variables.adults,
          refundable: this.variables.refundable,
          greenKey: this.variables.greenKey,
          rooms: this.variables.rooms,
          feedbackId: this.variables.page === 1 ? this.feedbackId : null,
          breakfastIncluded:
            this.variables.amenities.includes("BREAKFAST_INCLUDED")
        }
      });
    },
    showMarker(selectedHotelId) {
      const hotel = this.hotels.find((h) => h.hotelId === selectedHotelId);
      const lngLat = new mapboxgl.LngLat(hotel.longitude, hotel.latitude);

      const description = MapsHelper.getPopupDescription(hotel, this.$t);
      if (this.mapPopup) {
        this.mapPopup.remove();
      }
      this.mapPopup = new mapboxgl.Popup()
        .setLngLat(lngLat)
        .setHTML(description);
      this.mapPopup.addTo(this.map);
    },
    gotoHotelReservation(hotelId) {
      if (this.timeoutCode) {
        // Stop polling if detailpage is loading
        clearTimeout(this.timeoutCode);
      }
      this.track("gotoHotelReservationSearchResults", {
        locationPath: this.variables.locationPath,
        hotelId: hotelId,
        checkin: this.variables.checkin,
        nights: this.variables.nights,
        adults: this.variables.adults,
        refundable: this.variables.refundable,
        greenKey: this.variables.greenKey,
        rooms: this.variables.rooms,
        breakfastIncluded:
          this.variables.amenities.includes("BREAKFAST_INCLUDED")
      });
      this.$router.push({
        name: this.PAGES.HotelReservationForm,
        params: {
          locationPath: this.variables.locationPath,
          hotelId: hotelId,
          checkin: this.variables.checkin,
          nights: this.variables.nights,
          adults: this.variables.adults,
          refundable: this.variables.refundable,
          greenKey: this.variables.greenKey,
          rooms: this.variables.rooms,
          feedbackId: this.feedbackId
        }
      });
    },
    gotoCityReservation() {
      this.$router.push({
        name: this.PAGES.CityReservationForm,
        params: {
          city: this.variables.locationPath,
          checkin: this.variables.checkin,
          nights: this.variables.nights,
          adults: this.variables.adults,
          rooms: this.variables.rooms,
          latitude: this.location.latitude,
          refundable: this.variables.refundable,
          greenKey: this.variables.greenKey,
          longitude: this.location.longitude
        }
      });
    },

    async loadHotels() {
      try {
        MapsHelper.clearMap(
          this.map,
          this.map.getStyle().layers,
          this.map.getStyle().sources
        );
      } catch (e) {
        /// Map is empty
      }
      this.errorMessage = null;
      this.isTonightSearch = moment(this.variables.checkin).isSame(
        new Date(),
        "day"
      );

      const url = `${this.AVAILABILITY_API_URL}/hotel/getwithpricesbygeopath`;
      let { data, status } = await this.axios.post(url, this.variables);
      if (status !== 200) {
        this.loadingData = {
          progress: 100,
          hotelCount: 0
        };
        this.errorMessage = this.$t("serverErrorTryAgain");
        this.hotels = [];
        return;
      }
      if (!("progress" in data)) {
        data.progress = 0;
      }
      this.feedbackId = data.feedbackId;

      this.loadingData = {
        progress: data.progress,
        hotelCount: data.hotelCount
      };
      this.variables.feedbackId = data.feedbackId;
      this.location = data.geoPath;
      this.hotelCount = data.hotelCount;
      this.pageCount = data.pageCount;
      let hotels = data.hotels;
      hotels.forEach((h) => {
        let resolved = this.$router.resolve({
          name: this.PAGES.HotelDetail,
          params: {
            locationPath: this.variables.locationPath,
            hotelId: h.hotelId,
            checkin: this.variables.checkin,
            nights: this.variables.nights,
            adults: this.variables.adults,
            refundable: this.variables.refundable,
            greenKey: this.variables.greenKey,
            rooms: this.variables.rooms,
            feedbackId: this.feedbackId,
            breakfastIncluded:
              this.variables.amenities.includes("BREAKFAST_INCLUDED")
          }
        });

        h.link = resolved.href;
      });
      this.hotels = hotels;
      this.coordinates = `${this.location.longitude}${this.location.latitude}`;
      this.name = this.location.name;

      if (data.feedbackId && data.feedbackId != this.$route.params.feedbackId) {
        this.$router.replace({
          name: this.PAGES.SearchResult,
          params: {
            locationPath: this.variables.locationPath,
            checkin: this.variables.checkin,
            nights: this.variables.nights,
            adults: this.variables.adults,
            rooms: this.variables.rooms,
            refundable: this.variables.refundable,
            greenKey: this.variables.greenKey,
            feedbackId: data.feedbackId
          }
        });
      }

      if (data.progress < 100 && data.hotelCount > 0) {
        this.timeoutCode = setTimeout(() => {
          this.loadHotels();
        }, 500);
      } else if (data.hotelCount == 0) {
        this.loadingData = {
          progress: 100,
          hotelCount: 0
        };
      }
      this.loadMap(this.location);
    },
    selectedLocation(locationPath) {
      this.variables.locationPath = locationPath;
      this.$router.replace({
        name: this.PAGES.SearchResult,
        params: {
          locationPath: this.variables.locationPath,
          checkin: this.variables.checkin,
          nights: this.variables.nights,
          adults: this.variables.adults,
          rooms: this.variables.rooms,
          refundable: this.variables.refundable,
          greenKey: this.variables.greenKey,
          feedbackId: this.feedbackId
        }
      });
    }
  }
};
</script>

<style lang="scss">
body {
  background: darken(#fff, 3%);
}
#progress-indicator {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: orange;
  color: white;
  padding: 10px 5px;
}
</style>
