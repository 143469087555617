import moment from "moment";
import axios from "axios";
const USERNAME_KEY = "username";

const TOKEN_KEY = "token";
const COMPANYINFO_KEY = "companyInfo";
const USERDATA_KEY = "userdata";

const STYLESHEETID = "brandSpecificStyleSheet";

const ENVLS_KEY = "apiEnv";

/**
// The api uses startDate + number of nights instead of startdate/enddate. This function calculates the
// enddate based on the startdate and number of nights
 * @param {string} startDate - The start date in the format "YYYY-MM-DD".
 * @param {number} nights - The number of nights to include in the date range.
 * @returns {Object|null} An object containing the start date, end date, an array of dates, and formatted start and end date strings.
 *                        Returns null if the start date is not provided.
 * @property {string} startDate - The formatted start date.
 * @property {string} endDate - The formatted end date.
 * @property {Array<string>} dates - An array of formatted date strings for each night.
 * @property {string} startDateString - The start date formatted as "D MMMM" in Dutch locale.
 * @property {string} endDateString - The end date formatted as "D MMMM" in Dutch locale.
 */
const getDateRange = (startDate, nights) => {
  if (!startDate) {
    console.error("Please supply a startdate");
    return null;
  }
  startDate = moment(startDate, "YYYY-MM-DD");
  let endDate = moment(startDate).add(nights, "days");

  let dates = [];
  for (let i = 0; i < nights; i++) {
    let night = moment(startDate).add(i, "days").format();
    dates.push(night);
  }

  return {
    startDate: startDate.format(),
    endDate: endDate,
    dates,
    startDateString: startDate.format("D MMMM", "nl"),
    endDateString: endDate.format("D MMMM", "nl")
  };
};

// These functions are used to store and retrieve data from localstorage, mainly preventing errors
const storageSetObject = (userData) => {
  let jsonString = JSON.stringify(userData);
  localStorage.setItem(USERDATA_KEY, jsonString);
};
const storageGetObject = () => {
  if (localStorage.getItem(USERDATA_KEY)) {
    return JSON.parse(localStorage.getItem(USERDATA_KEY));
  } else {
    return {};
  }
};

const storageGetValue = (key) => {
  let userData = storageGetObject();
  return userData ? userData[key] : null;
};
const storageSetValue = (key, value) => {
  let userData = storageGetObject();
  if (!userData) {
    userData = {};
  }
  userData[key] = value;

  storageSetObject(userData);
};

// This method clears the storage, but keeps the api environment if thats set
const clearStorage = () => {
  const apiEnv = localStorage.getItem(ENVLS_KEY);

  localStorage.clear();
  if (apiEnv) {
    localStorage.setItem(ENVLS_KEY, apiEnv);
  }
};

const saveSession = (userName) => {
  localStorage.setItem(USERNAME_KEY, userName);
};
const saveToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const saveCompanyInfo = (data) => {
  localStorage.setItem(COMPANYINFO_KEY, JSON.stringify(data));
};

const hasCompanyInfo = () => {
  let hasCompanyInfo = !!localStorage.getItem(COMPANYINFO_KEY);
  return hasCompanyInfo;
};

// This method creates a hash based on the current date and time of day which is based on the 12 hour clock, so it can be used to check if the data is still valid/recent
// Basically it makes sure the data is not older than a day
const getDateHash = () => {
  const timeOfDay = new Date().getHours() > 12 ? "pm" : "am";
  return (
    new Date().getFullYear() +
    "" +
    (new Date().getMonth() + 1) +
    "" +
    new Date().getDate() +
    "" +
    timeOfDay
  );
};
const fetchCompanyInfo = async () => {
  let url = `${getApiURL()}/company/company-info`;
  let { data } = await axios.get(url);
  if (data) {
    saveCompanyInfo(data);
    localStorage.ciDate = getDateHash();
    return data;
  } else {
    return null;
  }
};

const getCompanyInfo = async () => {
  let companyData;
  if (localStorage.getItem(COMPANYINFO_KEY)) {
    companyData = JSON.parse(localStorage.getItem(COMPANYINFO_KEY));
  } else {
    companyData = await fetchCompanyInfo();
  }
  return companyData;
};

const getCompanyInfoSync = () => {
  if (localStorage.getItem(COMPANYINFO_KEY)) {
    return JSON.parse(localStorage.getItem(COMPANYINFO_KEY));
  } else {
    return {};
  }
};

const getBusinessUnits = async () => {
  let companyInfo = await getCompanyInfo();
  let businessUnits = companyInfo.businessUnits;

  companyInfo.businessUnits.forEach((bU) => {
    businessUnits.push(bU.businessUnits);
  });
  let allBusinessUnits = businessUnits.flat();

  return allBusinessUnits;
};

// The annotations can be used to show additional information above the search results.
// This function retrieves the annotations in the correct language
const getCompanyAnnotations = async () => {
  const languageIso = localStorage.locale || "NL";
  let companyInfo = await getCompanyInfo();
  let annotations = companyInfo.annotations.filter(
    (a) => a.languageIso === languageIso.toUpperCase()
  );
  return annotations;
};

/**
 * This is a helper to get locations/hotels from user input
 *
 * @param {string} searchInput - The input string to search for locations.
 * @returns {Promise<Object>} A promise that resolves to the location data.
 */
const getLocationsFromSearch = async (searchInput) => {
  let url = `${getApiURL()}/autocomplete/geopath/nl/${searchInput}/?amount=70`;
  const { data } = await axios.get(url);
  return data;
};

// This function checks if the session is still valid by calling the api and judging the response
const sessionIsValid = async () => {
  const url = `${getApiURL()}/authentication/validate`;

  try {
    let { data: validationData } = await axios.post(url);
    if (validationData.length && validationData[0].message) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
};

/**
 * This function invalidates the session, which means it logs out the user and clears the storage and stylesheets.
 * Usually triggered by a 401 error
 *
 * @async
 * @function invalidateSession
 * @param {boolean} [skipLogout=false] - If true, skips the logout process (used when assets give errors, usually those are stacked errors and can be ignored)
 * @returns {Promise<void>} - A promise that resolves when the session is invalidated.
 */
const invalidateSession = async (skipLogout = false) => {
  if (!skipLogout && isLoggedIn()) {
    const url = `${getApiURL()}/authentication/logout`;
    try {
      await axios.post(url);
    } catch (e) {
      console.error("Error logging out", e);
    }
  }
  clearStorage();

  if (document.getElementById(STYLESHEETID)) {
    document.getElementById(STYLESHEETID).remove();
  }
  return;
};

const isLoggedIn = () => {
  if (localStorage.getItem(USERNAME_KEY) !== null) {
    return true;
  } else {
    return false;
  }
};

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const hasToken = () => {
  if (getToken() !== null) {
    return true;
  } else {
    return false;
  }
};

const formatNumber = (number) => {
  return new Intl.NumberFormat("nl-NL").format(number);
};

const formatDecimals = (number) => {
  return new Intl.NumberFormat("nl-NL", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }).format(number);
};

const formatPrice = (number) => {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR"
  }).format(number);
};

// eslint-disable-next-line no-undef
const devTestApiUrl = `${process.env.VUE_APP_AVAILABILITY_API_URL_DEVTEST}`;
// eslint-disable-next-line no-undef
const testApiUrl = `${process.env.VUE_APP_AVAILABILITY_API_URL_TEST}`;
// eslint-disable-next-line no-undef
const stagingApiUrl = `${process.env.VUE_APP_AVAILABILITY_API_URL_STAGING}`;
// eslint-disable-next-line no-undef
const prodApiUrl = `${process.env.VUE_APP_AVAILABILITY_API_URL_PROD}`;

// You can use env=staging or env=test in the url to switch the api environment
// The used API is stored in the localstorage so it persists
const checkEnvFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams && urlParams.get("env")) {
    const envParamValue = urlParams.get("env");
    localStorage.setItem(ENVLS_KEY, envParamValue);
  }
};

/**
 * Determines the API environment based on the current URL and localstorage settings.
 *
 * @returns {string} The API environment, either "prod" or "test".
 */
const getApiEnv = () => {
  const prodUrl = "https://portal.lookingforbooking.com";
  var currentUrl = window.location.origin;

  let apiEnv = "test";
  if (localStorage.getItem(ENVLS_KEY) == null) {
    if (currentUrl == prodUrl) {
      apiEnv = "prod";
    } else {
      apiEnv = "test";
    }
  } else {
    apiEnv = localStorage.getItem(ENVLS_KEY) ?? "prod";
  }
  return apiEnv;
};

/**
 * Read the api env from the localstorage and return the correct api url
 *
 * This function determines the environment by calling `getApiEnv()` and returns
 * the corresponding API URL. The possible environments are "staging", "test",
 * "devtest", and the default environment which is assumed to be "production".
 *
 * @returns {string} The API URL corresponding to the current environment.
 */
const getApiURL = () => {
  checkEnvFromUrl();
  let apiUrl;
  const apiEnv = getApiEnv();
  switch (apiEnv) {
    case "staging":
      apiUrl = stagingApiUrl;
      break;
    case "test":
      apiUrl = testApiUrl;
      break;
    case "devtest":
      apiUrl = devTestApiUrl;
      break;
    default:
      apiUrl = prodApiUrl;
  }

  return apiUrl;
};

// For consisntency, the pages are stored in a constant so naming/renaming is easier and
// less error prone, plus it makes it easier to find all pages in the code
const PAGES = {
  Login: "login",
  SSO: "SSOPage",
  MFA: "MFAForm",
  UserProfile: "UserProfile",
  FlightForm: "FlightForm",
  LandingPage: "landingPage",
  SearchResult: "searchResult",
  ReservationOverview: "reservationOverview",
  HotelDetail: "hotelDetail",
  HotelReservationForm: "hotelreservation",
  HotelRebook: "hotelrebook",
  CityReservationForm: "cityreservation",
  MeetingRoomReservation: "meetingroomreservation"
};

const GEO_LOCATION_TYPES = {
  ALL: "all",
  HOTEL: "hotel",
  CITY: "city",
  AIRPORT: "airport"
};
const MeetingRoomSelectables = {
  selectableFacilities: [
    "Beamer/Scherm",
    "Flipover",
    "Microfoon",
    "Geluidsinstallatie"
  ],
  selectableCatering: [
    "Koffie / Thee / Water",
    "Lunch",
    "Diner",
    "Frisdranken",
    "Borrel"
  ],
  roomLayouts: [
    "U-Shape",
    "Cabaret",
    "Square",
    "Theater",
    "Block",
    "School",
    "Boardroom",
    "Other"
  ]
};

const nationalities = [
  "Nederlandse",
  "Slowaakse",
  "Tsjechische",
  "Burger van Bosnië-Herzegovina",
  "Georgische",
  "Turkmeense",
  "Tadzjiekse",
  "Oezbeekse",
  "Oekraïense",
  "Kirgizische",
  "Moldavische",
  "Kazachse",
  "Belarussische",
  "Azerbeidzjaanse",
  "Armeense",
  "Russische",
  "Sloveense",
  "Kroatische",
  "Letse",
  "Estische",
  "Litouwse",
  "Marshalleilandse",
  "Myanmarese",
  "Namibische",
  "Albanese",
  "Andorrese",
  "Belgische",
  "Bulgaarse",
  "Deense",
  "Burger van de Bondsrepubliek Duitsland",
  "Finse",
  "Franse",
  "Jemenitische",
  "Griekse",
  "Brits burger",
  "Hongaarse",
  "Ierse",
  "IJslandse",
  "Italiaanse",
  "Joegoslavische",
  "Liechtensteinse",
  "Luxemburgse",
  "Maltese",
  "Monegaskische",
  "Noorse",
  "Oostenrijkse",
  "Poolse",
  "Portugese",
  "Roemeense",
  "Burger van de Sovjet-Unie",
  "San Marinese",
  "Spaanse",
  "Tsjecho-Slowaakse",
  "Vaticaanse",
  "Zweedse",
  "Zwitserse",
  "Oost-Duitse",
  "Brits onderdaan",
  "Eritrese",
  "Brits overzees burger",
  "Macedonische",
  "Kosovaarse",
  "Macedonische/Burger van Noord-Macedonië",
  "Algerijnse",
  "Angolese",
  "Burundese",
  "Botswaanse",
  "Burkinese",
  "Centraal-Afrikaanse",
  "Comorese",
  "Burger van Congo",
  "Beninse",
  "Egyptische",
  "Equatoriaal-Guinese",
  "Ethiopische",
  "Djiboutiaanse",
  "Gabonese",
  "Gambiaanse",
  "Ghanese",
  "Guinese",
  "Ivoriaanse",
  "Kaapverdische",
  "Kameroense",
  "Kenyaanse",
  "Zaïrese",
  "Lesothaanse",
  "Liberiaanse",
  "Libische",
  "Malagassische",
  "Malawische",
  "Malinese",
  "Marokkaanse",
  "Mauritaanse",
  "Mauritiaanse",
  "Mozambikaanse",
  "Swazische",
  "Nigerese",
  "Nigeriaanse",
  "Ugandese",
  "Guinee-Bissause",
  "Zuid-Afrikaanse",
  "Eswatinische",
  "Zimbabwaanse",
  "Rwandese",
  "Burger van São Tomé en Principe",
  "Senegalese",
  "Sierra Leoonse",
  "Soedanese",
  "Somalische",
  "Tanzaniaanse",
  "Togolese",
  "Tsjadische",
  "Tunesische",
  "Zambiaanse",
  "Zuid-Soedanese",
  "Bahamaanse",
  "Belizaanse",
  "Canadese",
  "Costa Ricaanse",
  "Cubaanse",
  "Dominicaanse",
  "Salvadoraanse",
  "Guatemalaanse",
  "Haïtiaanse",
  "Hondurese",
  "Jamaicaanse",
  "Mexicaanse",
  "Nicaraguaanse",
  "Panamese",
  "Burger van Trinidad en Tobago",
  "Amerikaans burger",
  "Argentijnse",
  "Barbadaanse",
  "Boliviaanse",
  "Braziliaanse",
  "Chileense",
  "Colombiaanse",
  "Ecuadoraanse",
  "Guyaanse",
  "Paraguayaanse",
  "Peruaanse",
  "Surinaamse",
  "Uruguayaanse",
  "Venezolaanse",
  "Grenadaanse",
  "Burger van Saint Kitts en Nevis",
  "Afghaanse",
  "Bahreinse",
  "Bhutaanse",
  "Burmaanse",
  "Bruneise",
  "Cambodjaanse",
  "Sri Lankaanse",
  "Chinese",
  "Cyprische",
  "Filipijnse",
  "Taiwanese",
  "Indiase",
  "Indonesische",
  "Iraakse",
  "Iraanse",
  "Israëlische",
  "Japanse",
  "Noord-Jemenitische",
  "Jordaanse",
  "Koeweitse",
  "Laotiaanse",
  "Libanese",
  "Maldivische",
  "Maleisische",
  "Mongolische",
  "Omaanse",
  "Nepalese",
  "Noord-Koreaanse",
  "Pakistaanse",
  "Qatarese",
  "Saoedi-Arabische",
  "Singaporese",
  "Syrische",
  "Thaise",
  "Burger van de Verenigde Arabische Emiraten",
  "Turkse",
  "Zuid-Jemenitische",
  "Zuid-Koreaanse",
  "Vietnamese",
  "Bengalese",
  "Australische",
  "Papoea-Nieuw-Guinese",
  "Nieuw-Zeelandse",
  "West-Samoaanse",
  "Samoaanse",
  "Burger van Antigua en Barbuda",
  "Vanuatuaanse",
  "Fijische",
  "Burger van Britse afhankelijke gebieden",
  "Tongaanse",
  "Nauruaanse",
  "Palause",
  "Amerikaans onderdaan",
  "Salomonseilandse",
  "Micronesische",
  "Seychelse",
  "Kiribatische",
  "Tuvaluaanse",
  "Saint Luciaanse",
  "Burger van Dominica",
  "Burger van Saint Vincent en de Grenadines",
  "British National (overseas)",
  "Burger van Democratische Republiek Congo",
  "Burger van Timor Leste",
  "Burger van Servië en Montenegro",
  "Servische",
  "Montenegrijnse"
];

export default {
  getDateRange,
  nationalities,
  storageGetValue,
  storageSetValue,
  saveCompanyInfo,
  hasCompanyInfo,
  getCompanyInfo,
  getApiEnv,
  fetchCompanyInfo,
  getBusinessUnits,
  getCompanyAnnotations,
  getCompanyInfoSync,
  saveSession,
  clearStorage,
  saveToken,
  formatNumber,
  getDateHash,
  formatDecimals,
  getLocationsFromSearch,
  formatPrice,
  invalidateSession,
  sessionIsValid,
  isLoggedIn,
  hasToken,
  getToken,
  GEO_LOCATION_TYPES,
  PAGES,
  getApiURL,
  MeetingRoomSelectables
};
