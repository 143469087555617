import "@/assets/styles/main.scss";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Vue from "vue";
import VueAxios from "vue-axios";
import * as VueRouter from "vue-router";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "@vuepic/vue-datepicker/dist/main.css";

// This is the main template file for the application
import App from "@/App.vue";
import { mixins } from "@/helpers/mixins.js";

import {
  routes,
  checkRoute,
  checkSessionValidity
} from "@/helpers/routes.auth.helper.js";

import NotificationMessage from "@/components/shared/NotificationMessage.vue";
import i18n from "@/i18n";
import CommonHelper from "@/helpers/common.helper";

// This is an enum containing all the pages in the application
const PAGES = CommonHelper.PAGES;

// This loads the application and sets the languagetool
const app = Vue.createApp(App).use(i18n);

// This creates the router for the application and sets the routes from the import
const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
});

// This checks the route before each navigation if the user has
router.beforeEach(checkRoute);

window.dataLayer = window.dataLayer || [];

// This plugin reads the svg files and injects them into the html
app.use(VueSvgInlinePlugin, {
  attributes: {
    data: ["src"],
    remove: ["alt"]
  }
});
app.mixin(mixins);
app.use(router);

// Axios is used to make http requests
app.use(VueAxios, axios);
app.component("NotificationMessage", NotificationMessage);
axios.defaults.withCredentials = true;

// On startup we check if the session is still valid
checkSessionValidity(router);

// This is a list of paths that should be ignored when checking for errors, because they are always tried to be accessed,
//  but its not a problem if they are not found or if the user is not logged in, since that will be caught elsewhere
const ignoredErrorPaths = [
  "brand.css",
  "checktwofactor",
  "setcookie",
  "logout"
];
const checkIfUrlContainsIngnoredPaths = (url) => {
  return ignoredErrorPaths.some((path) => url.includes(path));
};

// This is an interceptor that catches errors and logs them
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const statusCode = error.response.status;
    console.log("===== ERROR CAUGHT ===== ", error);

    if (statusCode === 401) {
      let responseUrl = error.request.responseURL;
      if (checkIfUrlContainsIngnoredPaths(responseUrl)) {
        await CommonHelper.invalidateSession(true);
        if (window.location.pathname !== "/") {
          router.push({ name: PAGES.Login });
        }
      }
    }
    if (statusCode === 500) {
      console.error(error.code, error.message);
    }
    return error.response;
  }
);

app.mount("#app");
