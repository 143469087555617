<template>
  <div class="mb-3">
    <i
      class="fas fa-thin fa-money-bill-transfer pr-3"
      :class="{
        nonAvailable: !offer.isRefundable
      }"
      :title="
        offer.isRefundable
          ? $t('offerIsRefundable')
          : $t('offerIsNotRefundable')
      "
    ></i>

    <i
      class="fas fa-solid fa-bread-slice pr-3"
      :class="{
        nonAvailable: !offer.isBreakfastIncluded
      }"
      :title="
        offer.isBreakfastIncluded
          ? $t('breakfastAvailable')
          : $t('breakfastNotAvailable')
      "
    ></i>
    <span v-if="nights == 1" v-html="toPriceLabel(offer.price)"></span>
    <template v-else>
      <span>
        {{ toPriceLabel(offer.price) }}
        <br
      /></span>
      <small>{{ getPricePerNight(offer) }} {{ $t("perNight") }}</small>
    </template>
  </div>
</template>

<script>
import HotelHelper from "@/helpers/hotel.helper.js";

export default {
  props: {
    nights: {
      type: String,
      default: "1"
    },
    offer: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getPrice(offer) {
      return HotelHelper.getPrice(offer);
    },
    getPricePerNight(offer) {
      return HotelHelper.getPricePerNight(offer, this.nights);
    }
  }
};
</script>
