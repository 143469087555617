<template>
  <div class="details__pricebar">
    <template v-if="lowestOffer">
      <div
        class="columns is-multiline is-align-content-center is-align-items-center has-text-centered"
      >
        <div class="column is-one-fifth"></div>
        <div class="column is-one-fifth">
          <strong>
            <span class="title is-5">
              <OfferSummary :nights="nights" :offer="lowestOffer">
              </OfferSummary>
            </span>
          </strong>
        </div>

        <div class="column py-0">
          <span class="details__price__additional py-3">
            <template v-if="isRecommendedHotel(hotel)">
              <span class="c-direct-contracted-label c-tooltip">
                <i class="fa fa-star"></i>
                {{ $t("recommended.hotel") }} hotel
                <div
                  class="c-tooltip--content"
                  v-html="thtml('recommendedTitle')"
                ></div>
              </span>
            </template>
          </span>
        </div>
        <div class="column is-one-fifth">
          <button type="button" class="c-button">
            {{ $t("requestReservation") }}
          </button>
        </div>
        <div class="column is-one-fifth"></div>
        <div class="column is-full is-hidden-touch">
          <div class="details__price--basis">
            <span>
              {{
                $t("basedOn", {
                  count: parseInt(nights),
                  adults: parseInt(adults),
                  rooms: parseInt(adults),

                  startDate: startDate,
                  endDate: endDate
                })
              }}
              {{ $t("basedOnChange") }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-one-fifth"></div>

        <div class="column is-half">
          <p class="is-size-7">
            {{ $t("noPriceFoundRequestAnyway") }}
            <!--
            {{
              $t("basedOn", {
                count: parseInt(nights),
                adults: parseInt(adults),
                rooms: parseInt(adults),
                startDate: startDate,
                endDate: endDate,
              })
            }}
            {{ $t("basedOnChange") }}-->
          </p>
        </div>
        <div class="column" v-if="lowestOffer">
          <button type="button" class="c-button">
            {{ treq("requestReservation") }}
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import HotelHelper from "@/helpers/hotel.helper.js";
import CommonHelper from "@/helpers/common.helper.js";
import OfferSummary from "@/components/hoteldetail/OfferSummary";

export default {
  name: "DetailPriceBar",
  props: {
    hotel: {},
    checkin: String,
    nights: String,
    adults: String,
    rooms: String
  },
  components: {
    OfferSummary
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      lowestOffer: null,
      suppliersWithOffers: []
    };
  },
  methods: {
    getPrice(offer) {
      return HotelHelper.getPrice(offer);
    },
    getPricePerNight(offer) {
      return HotelHelper.getPricePerNight(offer, this.nights);
    }
  },

  created() {
    let dateRange = CommonHelper.getDateRange(this.checkin, this.nights);
    this.startDate = dateRange.startDateString;
    this.endDate = dateRange.endDateString;
  },
  watch: {
    hotel: {
      handler(newValue) {
        if (newValue.suppliers) {
          let priceSummary = HotelHelper.getPriceSummary(newValue.suppliers);
          this.lowestOffer = priceSummary.lowestOffer;
          this.suppliersWithOffers = priceSummary.suppliersWithOffers;
        }
      },
      immediate: true
    }
  },
  computed: {
    totalOfferCount() {
      let offerCount = 0;
      this.suppliersWithOffers.forEach(
        (swo) => (offerCount += swo.offers.length)
      );
      return offerCount;
    }
  }
};
</script>
<style lang="scss" scoped>
.details__pricebar {
  position: fixed;
  background: white;
  padding: 12px 20px;
  box-shadow: -1px -6px 7px 5px #ccc;
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>
